@import '../../../styles/_variables.scss';

.header {
  height: 66px;
  box-shadow: inset 0 -0.25px 0 0 $primary-border-line-color;
  display: flex;
  padding: 16px 16px 16px 24px;
  align-items: center;
  justify-content: space-between;

  p {
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  svg {
    height: 32px;
    width: 32px;
    fill: #3a86ff;
  }
}
