@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.container {
  height: 62px;
  padding: 10px 16px;
  position: sticky;
  top: env(safe-area-inset-top);
  border-bottom: 1px solid $primary-border-line-color;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
}

.filters {
  gap: 16px;
  display: flex;
  align-items: center;
}

.select {
  width: 180px;
  min-width: unset;
}

@include bp-max('sm') {
  .container {
    height: 110px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 12px;
    overflow: hidden;
    padding: 10px 8px;
  }

  .filters {
    gap: 8px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    width: 100%;
  }

  .search,
  .filterSelect {
    max-width: 100%;
  }

  .hiddenOnMobile {
    display: none;
  }
}
