@import '../../../../styles/variables.scss';
@import '../../../../styles/_breakpoints.scss';

.threadEditorWrapper {
  margin-left: 48px;
  width: calc(75% - 48px);
}

.threadEditorButton {
  margin: 16px 0;

  button {
    text-transform: unset;
    font-size: 14px;
    line-height: normal;
  }
}

.buttonGroup {
  width: 100%;
  box-shadow: unset;
  text-align: right;
  display: flex;
  justify-content: right;
}

.optionButton {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;

  padding: 14px 26px;
  width: 50px;
}

.threadMessageUploadContainer {
  margin: 12px 0px;
}

.actionButton {
  width: 100%;
  width: 230px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: white;
  padding: 0;
}

@include bp-max('md') {
  .threadEditorWrapper {
    margin-left: 0px;
    width: 100%;
  }
}
