@import '../../../../styles/_variables.scss';

.wrapper {
  display: block;
}

.button {
  padding: 0rem;
  height: 2.75rem;
  margin-top: 0.875rem;

  height: 2rem;
  margin: 0rem 0rem 0rem 1.4375rem;
  border-left: 1px solid #afb8cfa3;
  border-radius: 0rem;
}

.icon {
  margin-left: 1rem;
}

.loading {
  margin-left: 1rem;
  margin-right: 1.5rem;
  height: 1.5rem !important;
  width: 1.5rem !important;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.text {
  span {
    color: $secondary-text-color;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    padding-left: 0.875rem;
  }
}

.activeLinkClass a {
  background: #e9f5fe;
  border-left: 1px solid #0c7fda6b !important;

  .text {
    span {
      color: $secondary-active-link-color;
      font-weight: 600;
    }
  }

  .icon {
    color: $secondary-active-link-color;

    div {
      background: $secondary-active-link-color;
    }

    svg {
      path {
        fill: $secondary-active-link-color;
      }
    }
  }
}
