@import '../../../styles/_variables.scss';
@import '../../../styles/_breakpoints.scss';

.nav {
  display: none;
  position: fixed;
  top: env(safe-area-inset-top);
  z-index: 10;
  background-color: $white;
  width: 100%;

  &::after {
    content: '';
    position: absolute;
    bottom: -20px;
    height: 20px;
    width: 100%;
    left: 0;
    z-index: 1;
    background: $fade-gradient;
  }
}

.tab {
  flex: 1;
}

@include bp-max('sm') {
  .nav {
    display: block;
  }
}
