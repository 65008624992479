@import '../../../../styles/_variables.scss';

.chevron {
  display: flex;
  align-items: center;
  padding: 0rem;

  position: absolute;
  top: 50%;
  right: 0.25rem;

  height: 2.625rem;
  width: 0.75rem;
  border-radius: 0.125rem;
}
