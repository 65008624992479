@import '../../../../styles/variables.scss';

.profile {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.75rem;

  & > p {
    font-size: 1rem;
    font-weight: 500;
  }

  & > div:first-child {
    background-color: $secondary-color;
    height: 3rem;
    width: 3rem;
  }
}

.selectInput {
  width: 50%;

  & > div {
    padding: 8px;
  }
}

.item {
  height: 1.5rem;
}

.logoutButton {
  display: none;
  margin-top: 1rem;
  gap: 1rem;
  background: #667a8a;
}

.deleteButton {
  margin-top: 1rem;
  gap: 1rem;
  width: 100%;
  background-color: transparent;
  height: unset;
}

@media only screen and (max-width: 768px) {
  .logoutButton {
    display: inline-flex;
  }
}
