.documentUploadSection {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;

    p {
      max-width: 70%;
      text-align: left;
    }
  }

  .button {
    margin-top: 1rem;
    padding: 0.875rem 2rem;
    align-items: center;
    height: 3rem;
  }

  .errorButtonState {
    background-color: red;
  }

  .documentUploadSectionHelper {
    margin-top: 1.625rem;
  }
}

.fileUploadInput {
  width: 100%;
  height: 100%;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0 0 0 0);
  position: absolute;
}

.fileUploadTitle {
  width: 11.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helperText {
  line-height: 1rem;
  width: 15rem;
  margin: 0rem 0.5rem;
}

@media (max-width: 900px) {
  .documentUploadSection {
    & > div {
      // display: none;
      justify-content: center;
      p {
        max-width: 100%;
      }
    }
  }
}
