@import '../../styles/_variables.scss';
@import '../../styles/breakpoints.scss';

.wrapper {
  .drawerButton {
    color: black;
  }
}

.mobileOnly {
  display: none;
}

@include bp-max('md') {
  .mobileOnly {
    display: block;
  }
}
