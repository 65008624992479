@import '../../../../../styles/breakpoints.scss';

.buttons {
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;

  & > div {
    width: 25%;
  }

  .deleteButton {
    background-color: red;
    width: 100%;
  }

  .editButton {
    width: 100%;
  }
}

.editGroupModal {
  width: 60%;
  padding: 2% 6%;
}

@include bp-max('sm') {
  .editGroupModal {
    width: 90%;
    padding: 12px;
  }

  .buttons {
    & > div {
      width: 100%;
    }
  }
}
