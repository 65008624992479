@import '../../../styles/_variables.scss';

.list {
  padding: 0;
  max-height: 1900px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary-border-line-color;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  & > div {
    overflow: hidden;
  }
}

.subheader {
  background-color: $secondary-light;
}
