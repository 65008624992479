@import '../../../../../styles/_variables.scss';

.editor {
  padding: 8px 16px;
  color: #495057;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  min-height: 90px;
  line-height: 4px;
}

.toolbar {
  padding: 16px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border: unset;
  margin: 0;
}

.addMemberWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
  width: 100%;

  .addMemberButton {
    height: 38px;
    width: 220px;
    padding: 0;
    text-transform: unset;
    font-size: 12px;
    line-height: normal;
  }
  .addMemberButtonHelper {
    text-align: right;
    margin-right: 12px;
  }
}
