@import '../../../styles/_variables.scss';

.character {
  border-radius: 4px;
}

.characterSelected {
  border-color: $primary-color;
}

.characterInactive {
  background-color: $secondary-light;
}
