@import '../../styles/_variables.scss';

.modalTitle {
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin: 16px 0;
}
