@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.header {
  padding: 8px 16px;
  border-bottom: 1px solid $primary-border-line-color;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: $white;

  &::after {
    content: '';
    height: 20px;
    width: 100%;
    position: absolute;
    bottom: -20px;
    left: 0;
    background: $fade-gradient;
  }
}

.topRow {
  display: grid;
  gap: 12px;
  grid-template-columns: 1fr auto;
  margin-bottom: 8px;
  align-items: center;
}

.headerContent {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 12px;
}

.headerContentNavigation {
  display: flex;
  align-items: center;
  gap: 4px;

  & > div {
    display: flex;
    gap: 2px;

    button {
      padding: 2px;
    }
  }
}

.headerContentNavigationName {
  color: $secondary-text-color;
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.filters {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
}

.button {
  height: 38px;
  min-width: 120px;
  padding: 0;

  text-transform: unset;
  font-size: 12px;
  line-height: normal;
  padding: 0 4px;
}

.backButton {
  display: none;

  svg {
    font-size: 28px;
    fill: #1e1e1e;
  }
}

@include bp-max('sm') {
  .header {
    padding: 8px 12px;
  }

  .headerContentNavigationName {
    font-size: 16px;
  }

  .backButton {
    display: flex;
  }
}

@include bp-max('xs') {
  .headerContentNavigationName {
    font-size: 14px;
  }
}
