@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/breakpoints.scss';

.createModal {
  width: 60%;
  padding: 2% 6%;
}

@include bp-max('sm') {
  .createModal {
    width: 90%;
    padding: 12px;
  }
}
