@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/breakpoints.scss';

.createGroupModal {
  width: 60%;
  padding: 2% 6%;
}

@include bp-max('sm') {
  .createGroupModal {
    width: 90%;
    padding: 12px;
  }
}
