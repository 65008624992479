@import '../../../../styles/variables.scss';

.pagination {
  box-shadow: inset 0 0.25px 0 0 $primary-border-line-color;
  margin: 0px 16px;
  padding: 0px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
}
