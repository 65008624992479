@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_breakpoints.scss';

.attachedFile {
  background-color: #f8f9fd;
  display: flex;
  align-items: center;
  padding: 8px;
  gap: 4px;
  color: #667a8a;
  border: 1px dashed #667a8a8b;
  max-width: 320px;
  cursor: pointer;
  width: 100%;

  .attachedFileIcon, .attachedDownloadIcon {
    height: 32px;
    width: 32px;
  }

  .attachedFileIcon {
    display: block;
  }

  .attachedDownloadIcon {
    display: none;
  }

  .attachedFileDate {
    font-size: 0.75rem;
  }

  &:hover {
    background-color: transparent;

    .attachedFileIcon {
      display: none;
    }

    .attachedDownloadIcon {
      display: block;
    }
  }
}

.filename {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.logo {
  width: 32px;
  height: 32px;
}
