.container {
  max-width: 37rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 2rem;
}

.title {
  margin: 1.5rem 0;
  font-weight: 600;
}
