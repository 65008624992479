@import '../../styles/_variables.scss';
@import '../../styles/_breakpoints.scss';

.groupsMenu {
  width: 20%;
  z-index: 0;
  flex-shrink: 0;
  box-shadow: 0.25px 0 0 0px $primary-border-line-color;
  overflow-y: auto;
  max-width: 450px;
  max-height: 100vh;
  position: sticky;
  top: env(safe-area-inset-top);
}

.hiddenOnMobile {
  display: block;
}

@include bp-max('xl') {
  .groupsMenu {
    min-width: 300px;
  }
}

@include bp-max('lg') {
  .groupsMenu {
    min-width: 250px;
  }
}

@include bp-max('sm') {
  .groupsMenu {
    position: unset;
    max-height: unset;
  }

  .hiddenOnMobile {
    display: none;
  }
}
