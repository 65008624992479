@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.itemButton {
  padding: 16px;
  display: flex;
  justify-content: space-between;
}
.itemInfo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.date {
  p {
    color: $secondary-active-link-color;
    font-size: 13.139px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.title {
  margin: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.titleText {
  color: #1e1e1e;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text {
  height: 48px;
  max-height: 48px;
  margin-top: 16px;
  width: 80%;

  .ellipsis {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word;

    * {
      color: $secondary-color;
      font-style: normal;
      line-height: normal;
      font-size: 14px !important;
      padding: 0;
      margin: 0;
      line-height: normal;
    }
  }
}

.createdBy {
  p {
    color: $secondary-color;
    font-size: 13.139px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

.stats {
  p {
    color: $secondary-active-link-color;
    font-size: 13.139px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 8px;

    span {
      margin-right: 12px;
    }
  }
}

@include bp-max('sm') {
  .itemButton {
    padding: 16px 0;
  }

  .titleText {
    font-size: 20px;
  }

  .text {
    width: unset;
  }
}
