@import '../../styles/_variables.scss';
@import '../../styles/_breakpoints.scss';


.dialogContainer {
  border-radius: 14px;
}

.actionButtons {
  padding: 12px 48px 28px 48px;

  .cancel {
  }

  .confirm {
    background-color: red;
  }
}


@include bp-max('md') {
  .actionButtons {
    padding: 12px;

    & > div {
      width: 50%;

      button {
        padding: 12px 42px;
      }
    }
  }
}

@include bp-max('xs') {
  .actionButtons {
    padding: 12px;

    & > div {
      width: 50%;

      button {
        padding: 12px 42px;
      }
    }
  }
}
