@import '../../../styles/_variables.scss';

.container {
  box-sizing: border-box;
  margin: 0 auto;
  font-family: $primary-font-family;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  position: relative;
  width: 100%;
  min-height: 100vh;
}

.title {
  h2 {
    font-size: 2rem;
  }
}

.stepperContainer {
  display: grid;
  width: 70vw;
}

.activeStepComponentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;

  & > form {
    width: 90%;
  }
}

@media (max-width: 768px) {
  .stepperContainer {
    justify-content: center;
    width: 90vw;
  }
}
