@import '../../styles/_variables.scss';

.selectWrapper {
  border-radius: 1.625rem;
  text-transform: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  .selectLabel {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.54);
    text-transform: none;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    margin: 0;
    padding: 0;
    top: -8px;
    left: -3px;
  }

  .select {
    height: 30px;
    border-radius: 15px;
    background-color: transparent;

    color: rgba(0, 0, 0, 0.54);
    text-transform: unset;
    font-size: 0.875rem;
    line-height: normal;
    color: white;
    padding: 0;
    min-width: 7.5rem;
    max-width: 18.75rem;

    font-size: 0.75rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);

      fieldset {
        border-color: rgb(220 220 220);
      }
    }

    & > div {
      padding: 0.75rem;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.54);

      text-transform: none;
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    input {
      height: 1.875rem;
    }
    svg {
      fill: rgba(0, 0, 0, 0.54);
    }

    fieldset {
      border: 1px solid rgba(0, 0, 0, 0.12) !important;
    }
  }

  .activeSelect {
    background-color: rgba(12, 127, 218, 0.08);

    & > div {
      color: #0c7fda !important;
    }

    &:hover {
      background-color: rgb(230 240 250);

      fieldset {
        border-color: rgb(220 220 220);
      }
    }
  }
}

.selectItem {
  font-size: 0.75rem;
  height: 1.5rem;

  text-transform: unset;
  line-height: normal;
}

@media (max-width: 768px) {
  .selectItem {
    min-height: 2rem;
  }
}
