.services {
  background-color: #4851d4;
}

.contactUs {
  * button {
    background-color: #4851d4;

    &:hover {
      background-color: #323894;
    }
  }
}
