@import '../../../../../styles/_variables.scss';

.metadataWrapper {
  display: flex;
  align-items: center;
  height: 40px;
  gap: 8px;

  .metadataAvatar {
    background-color: $secondary-color;
  }

  .metadataName {
    color: $secondary-color;
    font-family: 'Inter';
    font-weight: 500;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }

  .metadataNameLabel {
    font-size: 8px;
    background: #667a8a;
    padding: 2px 8px;
    border-radius: 6px;
    color: white;
    text-transform: lowercase;
    margin-left: 2px;
  }

  .metadataDate {
    color: $secondary-text-color;
    font-size: 12px;
    line-height: 24px;
    font-family: 'Inter';
  }

  .metadataEditLabel {
    color: $secondary-text-color;
    font-size: 12px;
    line-height: 24px;
  }
}
