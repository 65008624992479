@import '../../../styles/_variables.scss';
@import '../../../styles/_breakpoints.scss';

.bottomNavigation {
  display: none;
}

.wrapper {
  width: 100%;
}

.drawer {
  display: none;
}

.profile {
  color: $secondary-text-color;
  font-family: 'Poppins', sans-serif;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
}

.signout {
  border-radius: 0.25rem;
  background-color: $secondary-color;

  span {
    color: $primary-text-color;
    font-weight: 600;
  }
}

.avatar {
  font-size: 0.875rem;
  height: 2rem;
  width: 2rem;
  align-self: center;
  background: $secondary-color;
}

@include bp-max('sm') {
  .wrapper {
    padding-bottom: $bottom-nav-height;

    .bottomNavigation {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
      align-items: center;
      position: fixed;
      left: 0;
      bottom: env(safe-area-inset-bottom);
      z-index: 10;
      width: 100%;
      height: $bottom-nav-height;
      padding-top: 4px;

      .navAction {
        padding: 4px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2px;
        color: $secondary-text-color;
        min-width: unset;
      }

      & > div {
        display: flex;
        justify-content: center;
      }
    }

    .activeLinkClass a {
      background: #e9f5fe;
      border-radius: 0.25rem;

      span {
        color: $secondary-active-link-color;
        font-weight: 600;
      }

      svg {
        transform: scale(1.05);
        path {
          fill: $secondary-active-link-color;
        }
      }
    }
  }

  .drawer {
    display: block;

    &Content {
      width: 100vw;
      padding: 32px 16px;
      padding-top: calc(12px + env(safe-area-inset-top));
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .logo {
    width: 200px;
    margin: auto;
    display: block;
  }
}
