@import '../../../../styles/_variables.scss';

.wrapper {
  display: block;
}

.button {
  padding: 0rem;
  height: 2.75rem;
  margin-top: 0.875rem;

  &:hover {
    border-radius: 0.25rem;
  }
}

.icon {
  margin: 0rem 1rem;
  justify-content: center;
  min-width: 0rem;
  align-self: center;
  height: 1.5rem;
  width: 1.5rem;
  color: #5d7285;

  svg {
    height: 100%;
    width: 100%;
  }
}

.iconExpand {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.375rem;
}

.loading {
  margin-left: 1rem;
  margin-right: 1.5rem;
  height: 1.5rem !important;
  width: 1.5rem !important;

  svg {
    height: 1.5rem;
    width: 1.5rem;
  }
}

.text {
  span {
    color: $secondary-text-color;
    font-family: 'Poppins', sans-serif;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
  }
}

.activeLinkClass a {
  background: #e9f5fe;
  border-radius: 0.25rem;

  .text {
    span {
      color: $secondary-active-link-color;
      font-weight: 600;
    }
  }

  .icon {
    color: $secondary-active-link-color;

    div {
      background: $secondary-active-link-color;
    }

    svg {
      path {
        fill: $secondary-active-link-color;
      }
    }
  }
}

.collapseWrapper {
  margin-top: 0.125rem;
}
