@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/breakpoints.scss';

.wrapper {
  flex-direction: column;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
}

.plansContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
  align-items: stretch;
}

.planContainer {
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 380px;
  justify-content: space-between;
}
.name {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 12px 0px;
}

.description {
  color: $secondary-text-color;
}

.amountWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 16px 0px;
}

.amount {
  font-size: 2rem;
  font-weight: 800;
}

.interval {
  color: $secondary-text-color;
  font-size: 0.8rem;
}

.button {
  text-transform: none;
  background-color: #3a86ff;
  font-size: 16px;
  width: 100%;
}

.switchContainer {
  display: flex;
  align-items: center;
}

.buttonGroup {
  
  & > button {
    text-transform: none;
  }
}

@include bp-max('lg') {
  .planContainer {
    width: 300px;
  }
}

@include bp-max('md') {
  .plansContainer {
    flex-wrap: wrap;
  }

  .planContainer {
    width: 380px;
    padding: 0px 64px;
  }
}



@include bp-max('sm') {  
  .plansContainer {
    flex-direction: column;
  }

  .planContainer {
    padding: 0px;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 8px 0px;
    padding: 0px 16px;
  }

  .name {
    margin: 0px;
  }

  .amountWrapper {
    width: 40%;
    padding: 0px;
  }
  
  .infoWrapper {
    width: 60%;
  }


  .purchaseWrapper {
    width: 36%;
  }

  .button {
    width: 100%;
    margin-top: 8px;
  }

  .title {
    
  }
}

