@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.home {
  background:
    linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.8) 70%, rgba(255, 255, 255, 0.6) 0%),
    url('../../../../public/assets/pharmacy.webp') center center no-repeat;
  background-size: cover;
}

.services {
  background-color: $white;

  h1 {
    color: $primary-color;
  }

  & > div:first-child {
    color: #667a8a;
  }
}

.img {
  width: 100%;
  vertical-align: middle;
  height: 70vh;
  object-fit: cover;
  filter: contrast(80%);
}

@include bp-max('md') {
  .home {
    background:
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.8) 0%,
        rgba(255, 255, 255, 0.95) 20%,
        rgba(255, 255, 255, 0.95) 80%,
        rgba(255, 255, 255, 0.8) 100%
      ),
      url('../../../../public/assets/pharmacy.webp') center center no-repeat;
    background-size: cover;
  }

  .img {
    height: 30vh;
  }
}
