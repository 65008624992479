@import '../../../../styles/breakpoints';

.threadContentWrapper {
  display: flex;
  justify-content: space-between;
}

@include bp-max('lg') {
  .threadContentWrapper {
    display: block;
  }
}
