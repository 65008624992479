.threadWrapper {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .textValue {
    font-family: 'Courier New', Courier, monospace;
    line-height: 20px;
    font-size: 16px;
    font-weight: 500;
    p,
    span,
    div,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      padding: 0;
      margin: 0;
      display: inline;
      line-height: 20px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .title {
    // text-align: center;
    font-family: 'Courier New', Courier, monospace;
    line-height: 20px;
    font-size: 20px;
    font-weight: 700;
  }

  .text {
    * {
      font-family: 'Courier New', Courier, monospace;
      line-height: 20px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .list {
    margin: 0;
    list-style-type: '- ';
  }

  .logoWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .logo {
      width: 220px;
    }
  }

  .contactInfo {
    margin-top: 24px;
    text-align: center;
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

@page {
  size: auto; /* auto is the initial value */
  margin-top: 8px;
  margin-bottom: 8px;
}
