@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_breakpoints.scss';

.threadMessageWrapper {
  box-shadow: 0 1px 1.5px $secondary-text-color;
  border-radius: 8px;
  padding: 24px;
  // margin-bottom: 24px;
  margin: 2px 24px 24px 2px;

  &:hover {
    .messageButtons {
      visibility: visible;
    }
  }
}

.message {
  margin-left: 48px;

  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;

  & > p {
    color: #495057;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.messageButtons {
  display: flex;
  justify-content: flex-end;
  gap: 4px;
  height: 32px;
  visibility: hidden;

  button {
    border-radius: 12px;
    text-transform: unset;
    line-height: normal;
  }
}

.attachedFiles {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-top: 12px;
}

.messageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.threadMessageHighlightedWrapper {
  background-color: #f8f9fd;
}

@media (max-width: 1024px) {
  .threadMessageWrapper {
    margin: 2px 8px 24px 2px;
  }
}

@include bp-max('sm') {
  .threadMessageWrapper {
    padding: 12px;
  }

  .message {
    margin-left: 0;
  }
}
