@import '../../../../styles/_variables.scss';
@import '../../../../styles/_breakpoints.scss';

// .container {
//   width: 100%;
// }

.cardContainer {
  padding: 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  // TODO: Remove
  width: 100%;
}

.containerItem {
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 25%;
}

.containerItemLabel {
  color: $secondary-color;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .containerItemLabelIcon {
    height: 2.5rem;
    width: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}

.containerItemNumber {
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 2rem;
}

@include bp-max('lg') {
  .cardContainer {
    flex-wrap: wrap;
    gap: 1%;
    width: 100%;
  }

  .containerItem {
    width: 49%;
    padding: 1rem;
    gap: 0rem;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    flex: 1 1;
  }

  .containerItemLabel {
    .containerItemLabelIcon {
      height: 2rem;
      width: 2rem;
    }
  }
}

@include bp-max('md') {
  .container {
    flex-wrap: wrap;
    gap: 0.5rem;

    .containerItem {
      width: 49%;
      padding: 1rem;
      gap: 0rem;
      flex: auto;

      .containerItemLabel {
        .containerItemLabelIcon {
          height: 2rem;
          width: 2rem;
        }
      }
    }
  }
}
