@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.home {
  background:
    linear-gradient(90deg, rgba(9, 39, 126, 0.6) 0%, rgba(90, 101, 136, 0.8) 100%),
    url('../../../../public/assets/lab.webp') center center no-repeat;
  background-size: cover;
}

.services {
  background-color: $white;

  h1 {
    color: $primary-color;
  }

  & > div:first-child {
    color: #667a8a;
  }
}

@include bp-max('md') {
  .home {
    background:
      linear-gradient(90deg, rgba(9, 39, 126, 0.6) 0%, rgba(90, 101, 136, 0.8) 100%),
      url('../../../../public/assets/lab.webp') center center no-repeat;
    background-size: cover;
  }
}
