@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.container {
  min-height: calc(100vh - 2px);
  margin: 0px 12px;
  background-color: white;
  box-shadow: 0 0 1.5px $primary-border-line-color;
  width: calc(100% - 24px);
}

@include bp-max('sm') {
  .container {
    margin: 0px 8px;
    width: calc(100% - 16px);
  }
}
