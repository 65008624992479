@import '../../../../styles/_variables.scss';

.section {
  padding: 0% 5%;
  min-height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    padding: 3rem;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;

    div {
      width: 100%;
    }

    & > h1 {
      font-size: 2.25rem;
      font-weight: 700;
      padding: 1rem 0rem;
    }

    @media (min-width: 768px) {
      div {
        width: 80%;
      }
    }
  }

  .carousel {
    margin-top: 2rem;
    & > div > div {
      width: 85%;
    }
  }

  .cardItem {
    padding: 25% 5%;
    box-sizing: border-box;
    border-radius: 0.5rem;

    & > div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .cardItemIcon {
      background-color: #e9f5fe;
      padding: 1.5rem;
      border-radius: 0.5rem;
      width: 5.25rem;
      height: 5.25rem;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        font-size: 3rem;
      }
    }

    .cardItemTitle {
      font-size: 2rem;
      font-weight: 600;
      margin: 0.75rem 0;
    }

    .cardItemDescription {
      font-size: 1rem;
      color: $secondary-color;
    }
  }

  .cardItemActive {
    background-color: #e9f5fe;
    transition: background-color 0.2s ease 0.3s;

    .cardItemIcon {
      background-color: #3a85ffdc;
      color: white;
    }
  }

  .backButton {
    position: absolute;
    left: 0;
    top: 45%;
  }

  .nextButton {
    position: absolute;
    right: 0;
    top: 45%;
  }
}

@media only screen and (max-width: 768px) {
  .section {
    min-height: calc(70vh - 5rem);
    .title {
      padding: 2rem 1rem;
    }

    .carousel {
      width: 100%;
    }

    .cardItem {
      .cardItemTitle {
        font-size: 1.25rem;
        height: 3rem;
        display: flex;
        align-items: center;
      }

      .cardItemDescription {
        font-size: 0.75rem;
      }
    }

    .backButton,
    .nextButton {
      display: none;
    }
  }
}
