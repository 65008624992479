.loaderContainer {
  position: relative;
}

.container {
  max-width: 1200px;
}

.info {
  margin-bottom: 20px;
}
