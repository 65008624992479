@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.container {
  height: 62px;
  padding: 10px 16px;
  position: sticky;
  top: 0px;
  border-bottom: 1px solid $primary-border-line-color;
  background-color: $white;
  display: flex;
  align-items: center;
  gap: 24px;
  z-index: 1;
}

.controls {
  margin-left: auto;
}

@include bp-max('sm') {
  .container {
    padding: 10px 8px;
  }
}
