@import '../../../styles/_variables.scss';

.wrapper {
  font-family: $primary-font-family;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  // max-height: calc(100vh - $header-height);
  min-height: 100vh;
  justify-content: space-between;

  margin: 0 12px;

  background-color: white;
  border-radius: 7px;

  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 40px;
    padding: 0 16px;

    .search {
      width: 100%;
    }

    .filters {
      display: flex;
      gap: 8px;
    }
  }

  .speciality {
    font-size: 12px;
    background: #667a8a;
    padding: 2px;
    border-radius: 6px;
    color: white;
    text-align: center;
  }

  .pagination {
    display: flex;
    justify-content: center;
    height: 44px;
    align-items: center;

    .listItemsCount {
      position: absolute;
      right: 48px;
    }
  }

  .tableCell {
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
  }

  table {
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }
}
