@import '../../../styles/_variables.scss';

.item {
  height: 80px;
  box-shadow: inset 0 -0.25px 0 0 $primary-border-line-color;
  cursor: pointer;

  & > div {
    height: 100%;
    width: 100%;
  }

  .button {
    height: 100%;
    padding: 16px 24px 16px 24px;
  }

  .text {
    span {
      color: #000;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
  }
}

.activeLinkClass a {
  background: #f8f9fd;

  .text {
    span {
      color: $secondary-active-link-color;
      font-weight: 600;
    }
  }

  .icon {
    color: $secondary-active-link-color;

    div {
      background: $secondary-active-link-color;
    }

    svg {
      path {
        fill: $secondary-active-link-color;
      }
    }
  }
}
