.step {
  max-width: 800px;
  margin-top: 20px;
}

.info,
.qrCode {
  margin-bottom: 24px;
}

.buttons {
  display: flex;
  gap: 24px;
  align-items: center;
}

.verificationFormContainer {
  max-width: 400px;
  margin-bottom: 20px;
}
