@import '../../../../styles/_breakpoints.scss';

.groupEmptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    color: black;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

@include bp-max('sm') {
  .groupEmptyState {
    display: none;
  }
}
