@import '../../../../styles/variables.scss';

.list {
  margin: 0 16px;
  padding: 0;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary-border-line-color;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  & > div {
    overflow-x: hidden;
  }
}
