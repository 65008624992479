@import '../../../../../styles/_breakpoints.scss';

.buttons {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: center;

  & > div {
    width: 25%;
  }

  .deleteButton {
    background-color: red;
    width: 100%;
  }

  .editButton {
    width: 100%;
  }
}

@include bp-max('md') {
  .buttons {
    & > div {
      width: 33%;
    }
  }
}

@include bp-max('xs') {
  .buttons {
    & > div {
      width: 50%;
    }
  }
}
