@import '../../../../styles/_variables.scss';
@import '../../../../styles//breakpoints';

.section {
  width: 100%;
  min-height: calc(100vh - $header-height);
  display: flex;
  justify-content: space-between;
  padding: 5%;
  overflow-x: hidden;
}

.info {
  margin-top: 5%;
  width: 50%;
}

.infoTitle {
  font-size: 4rem;
  font-weight: 800;
}

.infoTitlePrescribing,
.infoTitleToday {
  border: 0.2rem solid;
  padding: 0 0.5rem;
  white-space: nowrap;
}

.infoTitlePrescribing {
  border-color: rgba(58, 133, 255, 0.8);
}

.infoTitleToday {
  border-color: rgba(137, 194, 173, 0.8);
}

.description {
  margin: 3rem 0;
  font-size: 0.875rem;
  color: $secondary-color;
}

.joinButton {
  text-transform: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.5rem;
  height: 3.5rem;
}

.imageContainer {
  margin-top: 5%;
  width: 45%;
  height: 50vh;
  background: rgb(137, 194, 173);
  background: linear-gradient(-90deg, rgba(137, 194, 173, 1) 36%, rgba(58, 134, 255, 1) 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    .image {
      transform: scale(1.05);
      box-shadow: 1px 4px 20px 0px rgba(0, 0, 0, 0.2);
    }
  }
}

.image {
  background:
    linear-gradient(90deg, rgba(137, 194, 173, 0) 36%, rgba(58, 134, 255, 1) 100%),
    url('../../../../../public/assets/home.webp') top left no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  position: absolute;
  left: 40px;
  top: 40px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  transition: all 0.5s;
}

.descriptionTitle {
  font-weight: 500;
  margin: 12px 0px;
  font-size: 1rem;
}

.links {
  display: flex;
  align-items: center;
}

.storeLinks {
  gap: 8px;
  display: flex;
  margin: 0px 16px;
  
  & > a {
    height: 40px;
  }
}

@include bp-max('md') {
  .section {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    overflow: hidden;
    padding: 0px 16px;
    gap: 16px;
  }

  .image {
    top: 20px;
    left: 20px;
  }

  .imageContainer {
    height: 30%;
  }

  .infoTitle {
    font-size: 2.5rem;
  }

  .imageContainer,
  .info {
    width: 100%;
  }
}

@include bp-max('sm') {
  .section {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    gap: 45px;
    min-height: 100vh;
  }

  .imageContainer {
    height: 20vh;
    width: 60%;
  }

  .infoTitle {
    font-size: 2rem;
    text-align: center;
  }

  .joinButton {
    width: 100%;
  }

  .links {
    flex-direction: column;
  }

  .storeLinks {
    width: 100%;
    margin: 16px 0px;
  }
}
