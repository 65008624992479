@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_breakpoints.scss';

.threadDetailsWrapper {
  display: flex;
  flex-direction: column;
  width: 75%;
}

.threadDetailsText {
  color: #495057;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  padding-bottom: 18px;

  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.threadDetailsContent {
  margin-left: 48px;
  padding-bottom: 24px;
}

.threadDetailsTitleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  width: 100%;
  color: $secondary-active-link-color;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 18px 0;
}

.status {
  border-radius: 30px;
  background-color: $secondary-color;
  text-transform: unset;
  font-size: 12px;
  color: white;
  line-height: normal;
  text-align: center;
  width: 80px;
  padding: 4px;
  font-weight: 700;
}

.reviewCommentWrapper {
  padding: 8px;
  margin: 12px 0px 0px 0px;
  background-color: #f8f9fd;
  box-shadow: unset;

  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;

  &::before {
    background-color: transparent;
  }

  .summary {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;
    color: #495057;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
}

.attachedFiles {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}


@include bp-max('lg') {
  .threadDetailsWrapper {
    width: 100%;
  }
}

@include bp-max('sm') {
  .threadDetailsContent {
    margin-left: 0;
  }
}
