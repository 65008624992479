@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_breakpoints.scss';

.modal {
  gap: 0;
  width: 40%;
  min-width: 560px;
  padding: 1% 4%;

  form {
    gap: 0;
    height: 500px;
    overflow: auto;

    & > ul {
      overflow: auto;
      height: 700px;

      padding: 0;
    }

    & > div {
      width: 99%;
    }
  }
}

.search {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  padding-bottom: 12px;
  box-shadow: inset 0px -1.25px 0px -1px $primary-border-line-color;

  .filters {
    display: flex;
    gap: 12px;
  }
}

.infiniteScrollWrapper {
  width: 100%;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary-border-line-color;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.list {
  width: 100%;
}

.listItem {
  display: flex;
  height: 42px;
  flex-direction: row;
  justify-content: space-between;
  color: #667a8a;
  border-radius: 4px;

  a {
    color: #667a8a;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }
}

@include bp-max('xs') {
  .modal {
    min-width: 95vw;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
  }

  .listItem {
    padding: 8px;
    height: unset;
    flex-direction: column;
    border: 1px solid $primary-border-line-color;
    gap: 12px;

    & > div:first-child {
      flex-direction: column;
      align-items: center;
      gap: 4px;
      text-align: center;
    }
  }
}
