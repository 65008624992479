@import '../../styles/_variables.scss';

.container {
  text-align: center;

  .rounded {
    border-radius: 1.875rem;
  }
}

.button {
  text-align: center;
  font-style: normal;
  line-height: normal;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.875rem 4.75rem;

  border-radius: 0.5rem;
  text-transform: unset;
  height: 3.5rem;

  background-color: #3a86ff;

  span {
    color: white;
    min-height: 1.5rem;
    min-width: 1.5rem;
  }
}
