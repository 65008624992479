@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.section {
  width: 100%;
  height: calc(100vh - $header-height);
  min-height: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25vh 0 64px 64px;
  overflow: hidden;
  gap: 36px;
  position: relative;
}

.info {
  padding: 32px;
  border-radius: 12px;
  width: max(50%, 800px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.infoTitle {
  font-size: 3rem;
  font-weight: 800;
  opacity: 0;
  text-align: center;
  color: $white;

  animation: smooth-appear 0.75s ease forwards;

  em {
    border: 0.2rem solid $primary-color;
    padding: 0 0.5rem;
    white-space: nowrap;
    font-style: normal;
  }
}

.descriptionContainer {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  width: 100%;
}

.description {
  font-size: 1.125rem;
  color: $black;
  opacity: 0;
  color: $white;
  text-align: center;

  animation: smooth-appear-left 0.5s ease forwards;
}

.joinButton {
  width: 35%;
  text-transform: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.5rem;
  height: 3.5rem;
  box-shadow: none;
  opacity: 0;
  animation: smooth-appear 2s ease forwards;

  background-color: $white;
  color: $primary-color;

  &:hover {
    background-color: $white;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
  }
}

@keyframes smooth-appear-left {
  to {
    left: 50%;
    opacity: 0.75;
  }
}

@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}

@include bp-max('md') {
  .section {
    gap: 32px;
    padding: 32px;
  }

  .infoTitle {
    text-align: center;
  }

  .info {
    align-items: center;
  }

  .description {
    text-align: center;
  }

  .infoTitle {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .info {
    width: 80%;
  }
}

@include bp-max('sm') {
  .section {
    gap: 24;
    padding: 32px 16px;
    justify-content: center;
  }

  .infoTitle {
    font-size: 2rem;
    text-align: center;
  }

  .info {
    width: 100%;
    max-width: 400px;
    padding: 0px;
  }

  .description {
    min-width: 100%;
  }

  .joinButton {
    width: 100%;
  }
}
