@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_breakpoints.scss';

.container {
  width: 100%;

  & > header {
    position: sticky;
    top: 0px;
    background-color: $white;
    z-index: 10;
    padding: 12px 32px 12px 12px;

    .line {
      width: calc(100% - 44px);
      height: 1px;
      background-color: $primary-border-line-color;
      position: absolute;
      bottom: 0;
      opacity: 0.25;
    }

    &::after {
      content: '';
      height: 20px;
      width: 100%;
      position: absolute;
      bottom: -20px;
      left: 0;
      background: $fade-gradient;
    }
  }

  & > main {
    padding: 24px 32px 24px 12px;
  }
}

@include bp-max('md') {
  .container {
    & > header {
      padding: 12px;

      .line {
        width: calc(100% - 24px);
      }
    }

    & > main {
      padding: 24px 12px;
    }
  }
}

@include bp-max('xs') {
  .container {
    & > header {
      padding: 8px;

      .line {
        width: calc(100% - 16px);
      }
    }

    & > main {
      padding: 24px 8px;
    }
  }
}
