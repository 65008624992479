@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_breakpoints.scss';

.threadHeaderWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}

.threadHeaderName {
  color: #1e1e1e;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.threadHeaderInfo {
  display: flex;
  align-items: center;
  gap: 12px;

  svg {
    font-size: 28px;
    fill: #1e1e1e;
  }
}

.threadHeaderButtons {
  .buttonsWrapper {
    display: flex;
    gap: 12px;
    align-items: center;
  }

  .button {
    height: 38px;
    width: 110px;
    padding: 0;
    text-transform: unset;
    font-size: 12px;
    line-height: normal;
  }
}

@include bp-max('sm') {
  .threadHeaderName {
    font-size: 16px;
  }

  .threadHeaderButtons {
    .buttonsWrapper {
      gap: 10px;
    }

    button,
    .button {
      max-width: 110px;
    }
  }
}

@include bp-max('xs') {
  .threadHeaderWrapper {
    gap: 8px;
  }

  .threadHeaderName {
    font-size: 14px;
  }
}
