.container {
  margin: auto;
}

.input {
  margin: auto;
}

.button {
  margin-top: 24px;
}
