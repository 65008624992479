@import '../../../styles/breakpoints.scss';

.reviewComment {
  margin-bottom: 8px;
}

.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: none;
}

.actionButton {
  width: 290px;
}

.optionsButton {
  width: 120px;
  padding: 0 !important;
}

@include bp-max('sm') {
  .actionButton {
    width: 200px;
  }

  .optionsButton {
    width: 50px;
  }
}
