@import '../../../styles/_variables.scss';

.wrapper {
  font-family: $primary-font-family;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100vh;
  margin: 0 12px;

  background-color: white;
  border-radius: 7px;

  margin: 0 12px;

  .contentWrapper {
    padding: 24px;
  }

  .header {
    height: 66px;
    align-items: center;
    display: flex;
    margin: 0px 16px;
    box-shadow: inset 0px -0.75px 0 -0.5px $primary-border-line-color;
    gap: 12px;

    color: #1e1e1e;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    button {
      svg {
        font-size: 36px;
        fill: #1e1e1e;
      }
    }
  }

  .formWrapper {
    display: flex;
    align-items: center;

    gap: 12px;
  }

  .buttonGroup {
    width: 100%;
    box-shadow: none;
    display: flex;
    align-items: center;

    text-decoration: unset;
    text-transform: none;
    justify-content: right;

    .actionButton {
      width: 180px;

      padding: 2px 16px;
      height: 38px;
      font-size: 14px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;

      border-color: white;
    }

    .optionsButton {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      width: 20px;
      padding: 2px 16px;
      height: 38px;
      font-size: 14px;
      padding: 14px 36px;
    }
  }

  .deleteButton {
    background-color: red;
    width: 100%;
    padding: 2px 16px;
    height: 38px;
    font-size: 14px;
  }
}

.profileWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.profile {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 12px;

  & > p {
    font-size: 14px;
    font-weight: 500;
  }

  & > div:first-child {
    background-color: $secondary-color;
    height: 50px;
    width: 50px;
  }
}

.item {
  height: 21px;
}
