.stepContainer {
  text-align: center;

  .labelContainer {
    width: 100%;
    & > span {
      font-size: 0.75rem;
    }
  }
}

@media (max-width: 1200px) {
  .stepContainer {
    .labelContainer {
      display: none;
    }
  }
}
