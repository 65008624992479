@import '../../../../styles/_variables.scss';

.section {
  width: 100%;
  min-height: calc(100vh - $header-height);
  display: flex;
  justify-content: space-between;
  padding: 5%;

  .info {
    margin-top: 5%;
    width: 50%;

    .infoTitle {
      font-size: 4rem;
      font-weight: 800;

      .infoTitlePrescribing,
      .infoTitleToday {
        border: 0.2rem solid;
        padding: 0 0.5rem;
      }

      .infoTitlePrescribing {
        border-color: rgba(58, 133, 255, 0.8);
      }

      .infoTitleToday {
        border-color: rgba(137, 194, 173, 0.8);
      }
    }

    .description {
      margin: 3rem 0;
      font-size: 0.875rem;
      color: $secondary-color;
    }

    .joinButton {
      width: 35%;
      text-transform: none;
      text-align: center;
      font-size: 1rem;
      font-weight: 600;
      border-radius: 0.5rem;
      height: 3.5rem;
    }
  }

  .images {
    margin-top: 5%;
    width: 45%;
    height: 50vh;

    background: rgb(137, 194, 173);
    background: linear-gradient(9deg, rgba(137, 194, 173, 1) 36%, rgba(58, 134, 255, 1) 100%);
    border-radius: 0.5rem;

    display: flex;
    align-items: center;

    .image1 {
      height: 90%;
      width: 55%;
      border-radius: 0.5rem;
      position: relative;
      left: -5%;
      background: url('../../../../assets/home-first.webp') no-repeat center center / cover;
    }

    .imagesChild {
      height: 90%;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      right: 2.5%;

      .image2,
      .image3 {
        border-radius: 0.5rem;
        height: 47.5%;
      }

      .image2 {
        background: url('../../../../assets/home-second.webp') no-repeat center center / cover;
      }

      .image3 {
        background: url('../../../../assets/home-third.webp') no-repeat center center / cover;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section {
    flex-direction: column;
    padding: 8% 5%;

    .info {
      z-index: 1;

      .infoTitle {
        font-size: 3rem;
        width: 80%;
      }

      .description {
        font-size: 1rem;
      }

      .joinButton {
        text-align: right;
        display: flex;
      }
    }

    .info,
    .images {
      width: 100%;
    }

    .images {
      height: 25vh;
      width: 70%;
      gap: 0.5rem;
      right: 5%;
      position: absolute;
      opacity: 30%;
      z-index: 0;
    }
  }
}
