@import '../../styles/_variables.scss';
@import '../../styles/_breakpoints.scss';

.wrapper {
  background-color: white;
  flex-grow: 1;
  display: flex;
  margin: 0 12px;
  min-height: 100vh;
  padding: 0;
  border-radius: 7px;
}

@include bp-max('sm') {
  .wrapper {
    margin: 0;
  }

  .groupPageWrapper {
    min-height: calc(100vh - $bottom-nav-height);

    & > div {
      width: 100%;
      max-width: unset;
      padding-top: 60px;
    }
  }
}
