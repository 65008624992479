@import '../../styles/_variables.scss';

.title {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0px;
  background-color: $white;
  z-index: 10;

  & > h4 {
    font-size: 1.25rem;
  }
}

.description {
  color: $secondary-text-color;
  font-size: 0.875rem;
}
