@import '../../../styles/_variables.scss';
@import '../../../styles/_breakpoints.scss';

.wrapper {
  display: flex;
}

.sidebar {
  display: none;
}

@include bp-min('sm') {
  .sidebar {
    display: block;
    border-radius: 0.5rem;
    position: sticky;
    z-index: 1;
    padding-top: 12px;

    .list {
      padding: 0rem 1.25rem 1rem 1.25rem;
    }

    .profile {
      color: $secondary-text-color;
      font-family: 'Poppins', sans-serif;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      display: flex;
      align-items: center;
      width: 100%;
      gap: 4px;
    }

    .tierChip {
      height: 22px;

      & > span {
        color: #0c7fda;
        font-size: 12px;
        font-weight: 400;
        padding: 0px 6px;
      }
    }

    .signout {
      border-radius: 0.25rem;
      background: $secondary-color;

      span {
        color: $primary-text-color;
        font-weight: 600;
      }
    }

    .avatar {
      font-size: 0.875rem;
      height: 2rem;
      width: 2rem;
      align-self: center;
      background: $secondary-color;
    }

    .chevron {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0rem 0.5rem;
    }

    .companyLogo {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
    }
  }

  .navigation {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
  }

  .MuiDrawerPaper {
    position: fixed;
    z-index: 0;
    border-radius: 0.5rem;
    padding-top: calc(12px + env(safe-area-inset-top));
    padding-bottom: env(safe-area-inset-bottom);
    display: flex;
    height: 100%;
  }

  .logoIcon {
    width: 80%;
    animation: logoIconSlideIn 0.3s ease-in forwards;
  }

  .logo {
    width: 80%;
    animation: logoSlideIn 0.2s ease-in forwards;
  }
}

@keyframes logoIconSlideIn {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes logoSlideIn {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
