@import '../../../../../../styles/variables.scss';
@import '../../../../../../styles/breakpoints.scss';

.container {
  // overflow-y: hidden;
  overflow-x: hidden;

  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & > p {
    text-align: center;
    margin: 24px 0px;
  }
}
