@import '../../../styles/_variables.scss';
@import '../../../styles/breakpoints.scss';

.wrapper {
  font-family: $primary-font-family;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;

  margin: 0rem 0.75rem;

  background-color: white;
  border-radius: 0.5rem;

  .actionsWrapper {
    padding: 1rem;
  }

  .specialityFilter {
    width: 11.25rem;
  }

  .countryFilter {
    width: 10rem;
  }

  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 2.5rem;
    max-height: 3.25rem;

    .search {
      width: 100%;
    }

    .filters {
      display: flex;
      gap: 0.5rem;
    }
  }

  .speciality {
    font-size: 0.75rem;
    background: #667a8a;
    padding: 0.125rem;
    border-radius: 0.375rem;
    color: white;
    text-align: center;
  }

  .pagination {
    display: flex;
    justify-content: center;
    height: 2.75rem;
    align-items: center;
  }

  table {
    tbody {
      tr {
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  .listItemsCount {
    font-size: 0.75rem;
    margin-left: 0.5rem;
  }

  .tableCell {
    font-size: 0.75rem;
    font-style: normal;
    line-height: normal;
    font-weight: 600;

    svg {
      color: black;
    }
  }

  tr {
    height: 3.75rem;
  }
}

@include bp-max('sm') {
  .wrapper {
    min-height: calc(100vh - 4rem);
    margin: 0;

    .desktopOnly {
      display: none;
    }

    .actions {
      flex-direction: column;
      max-height: unset;
      align-items: flex-start;
      gap: 0rem;
    }
  }
}
