@import '../../../styles/_variables.scss';
@import '../../../styles/_breakpoints.scss';

.toastContainer {
  &:global(.Toastify__toast-container) {
    width: 500px;
  }

  & > div {
    border-radius: 8px;
    font-size: 1rem;
    font-style: normal;
    line-height: normal;
  }
}

:root {
  --toastify-color-success: #89c2ad;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #b90e0e;
}

@include bp-max('sm') {
  .toastContainer {
    &:global(.Toastify__toast-container) {
      width: 90vw;
    }

    &:global(.Toastify__toast-container--bottom-right) {
      bottom: unset;
      top: calc(safe-area-inset-top + 1rem);
      right: 1em;
      left: unset;
    }
  }
}

@include bp-max('xs') {
  .toastContainer {
    &:global(.Toastify__toast-container--bottom-right) {
      right: 0;
      left: unset;
    }
    &:global(.Toastify__toast-container--top-right) {
      right: 0;
      left: unset;
    }
  }
}
