// Common
$header-height: 5rem;
$bottom-nav-height: 56px;

// Colors
$white: #fff;
$black: #000;

$primary-color: #0c7fda;
$primary-text-color: #fff;
$secondary-light: #e6e6e6;
$secondary-color: #667a8a;
$secondary-text-color: #5d7285;
$secondary-active-link-color: #0c7fda;

$primary-border-line-color: #afb8cf;

$primary-header-color: #fafafa;
$primary-footer-color: #fafafaed;

$primary-group-color: #fafafaed;

// Font
$primary-font-family: 'Poppins', sans-serif;

// Status
$approved-status: #89c2ad;
$rejected-status: #b90e0e;
$changes-requested-status: #0c7fda;
$open-status: #667a8a;

$fade-gradient: linear-gradient(
  180deg,
  rgba(255, 255, 255, 0.9) 0%,
  rgba(255, 255, 255, 0.4) 50%,
  rgba(255, 255, 255, 0) 100%
);
$fade-gradient-reversed: linear-gradient(
  0,
  rgba(255, 255, 255, 0.9) 0%,
  rgba(255, 255, 255, 0.4) 50%,
  rgba(255, 255, 255, 0) 100%
);
