@import '../../../styles/_variables.scss';

.container {
  margin-bottom: 24px;
}

.title {
  font-size: 1rem;
  margin-bottom: 16px;
  border-bottom: 0.5px solid $primary-border-line-color;
}
