@import '../../../styles/variables.scss';

.container {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: $secondary-color;
}
