@import '../../../../../../../styles/breakpoints.scss';

.title {
  margin-bottom: 24px;
  text-align: center;
}

.buttons {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

@include bp-max('sm') {
  .buttons {
    gap: 8px;
  }
}
