@import '../../../styles/_variables.scss';
@import '../../../styles/breakpoints.scss';

.wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100vh;
  margin: 0rem 0.75rem;

  background-color: white;
  border-radius: 0.5rem;
}

.contentWrapper {
  padding: 1.5rem;
}

.header {
  height: 4.125rem;
  align-items: center;
  display: flex;
  margin: 0rem 1rem;
  gap: 0.75rem;
  background-color: $white;

  color: #1e1e1e;
  font-size: 1.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  border-bottom: 0.5px solid $primary-border-line-color;

  button {
    svg {
      font-size: 2.25rem;
      fill: #1e1e1e;
    }
  }
}

.profile {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.75rem;

  & > p {
    font-size: 1rem;
    font-weight: 500;
  }

  & > div:first-child {
    background-color: $secondary-color;
    height: 3rem;
    width: 3rem;
  }
}

.item {
  height: 1.5rem;
}

@include bp-max('sm') {
  .wrapper {
    min-height: calc(100vh - 4rem);
    margin: 0;
  }

  .header {
    gap: 0rem;
    margin: 0rem;
    font-size: 1.25rem;
  }

  .contentWrapper {
    padding: 8px;
  }
}
