@import '../../../../styles/_breakpoints.scss';
@import '../../../../styles/_variables.scss';

.loaderContainer {
  position: relative;
  margin-top: 16px;
}

.header {
  height: 66px;
  align-items: center;
  display: flex;
  box-shadow: inset 0px -0.75px 0 -0.5px $primary-border-line-color;
  gap: 12px;

  color: #1e1e1e;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  justify-content: space-between;
}

.headerTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    svg {
      font-size: 36px;
      fill: #1e1e1e;
    }
  }
}

.container {
  min-height: 100vh;
  margin: 0rem 0.75rem;
  padding: 0px 16px;

  background-color: white;
  border-radius: 0.5rem;
}

.description {
  & > p {
    height: unset;
  }
}

@include bp-max('sm') {
  .container {
    min-height: calc(100vh - 4rem);
    margin: 0;
    padding: 8px 8px;
  }
}
