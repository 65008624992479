@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_breakpoints.scss';

.threadMessagesWrapper {
  margin-bottom: 12px;

  & > div {
    margin-left: 48px;
  }
}

.threadMessagesBottomScroll {
  position: fixed;
  right: 12px;
  bottom: 70px;
  z-index: 2;
  display: none;

  height: 48px;
  width: 48px;

  background: #e9f5fe;

  & > svg {
    fill: #0c7fda;
    height: 38px;
    width: 38px;
  }
}

.timelineScrollbarWrapper {
  position: relative;
  max-height: 80vh;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    height: 20px;
    width: 100%;
    left: 0;
    z-index: 1;
    background: $fade-gradient;
    width: calc(75% - 8px);
  }

  &::after {
    top: unset;
    bottom: 0;
    background: $fade-gradient-reversed;
  }

  & > div {
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $secondary-text-color;
      border-radius: 10px;
    }

    &:hover::-webkit-scrollbar-thumb {
      background-color: $secondary-text-color;
      background-clip: content-box;
    }

    &::-webkit-scrollbar-track {
      background-color: $primary-border-line-color;
      border-radius: 10px;
    }
  }
}

.timelineMessages {
  width: 75%;
  max-height: 80vh;
  position: relative;
  margin-right: 120px;
  padding: 10px 0px;

  & > :nth-last-child(2) {
    margin-bottom: 0px;
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: calc(75% + 12px);
  width: 100px;
  max-height: 100px;
  z-index: 1;
  text-align: left;
  font-size: 12px;
}

.hideOverlay {
  visibility: hidden;
}

@media (max-width: 1024px) {
  .threadMessagesWrapper {
    & > div {
      margin-left: 0px;
      margin-right: 70px;
    }
  }

  .timelineScrollbarWrapper {
    overflow: visible;
    &::before,
    &::after {
      width: calc(95% - 8px);
    }
  }

  .timelineMessages {
    width: 95%;
    margin-right: 10px;
  }

  .overlay {
    position: absolute;
    left: calc(94% + 12px);
    max-height: 100px;
    z-index: 1;
    text-align: left;
  }
}

@include bp-max('sm') {
  .timelineScrollbarWrapper {
    max-height: unset;

    &::before, &::after {
      display: none;
    }
  }

  .threadMessagesBottomScroll {
    display: flex;
  }

  .timelineMessages {
    width: 100%;
    margin-right: 10px;
    overflow: visible;
    max-height: unset;
  }

  .threadMessagesWrapper {
    & > div {
      margin-right: 0;
    }
  }

  .overlay {
    display: none;
  }
}
