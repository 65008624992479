@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.home {
  background:
    linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.9) 50%),
    url('../../../../public/assets/regenerative-medicine.webp') center center no-repeat;
  background-size: cover;
}

.services {
  background-color: $white;

  h1 {
    color: $primary-color;
  }

  & > div:first-child {
    color: #667a8a;
  }
}

@include bp-max('md') {
  .home {
    background:
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 1) 25%,
        rgba(255, 255, 255, 1) 75%,
        rgba(255, 255, 255, 0.9) 100%
      ),
      url('../../../../public/assets/regenerative-medicine.webp') center center no-repeat;
    background-size: cover;
  }
}
