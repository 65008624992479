@import '../../../styles/_variables.scss';

.wrapper {
  font-family: $primary-font-family;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  // max-height: calc(100vh - $header-height);
  min-height: 100vh;
  justify-content: space-between;

  margin: 1px 12px;

  background-color: white;
  border-radius: 7px;

  box-shadow: 0 0 1.5px $primary-border-line-color;

  .actionsWrapper {
    padding: 16px;
  }

  .actions {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 40px;
    max-height: 52px;

    .search {
      width: 100%;
    }

    .filters {
      display: flex;
      gap: 8px;
    }
  }

  .speciality {
    font-size: 12px;
    background: #667a8a;
    padding: 2px;
    border-radius: 6px;
    color: white;
    text-align: center;
  }

  .pagination {
    display: flex;
    justify-content: center;
    height: 44px;
    align-items: center;
  }

  table {
    tbody {
      tr {
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }
  }

  .listItemsCount {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
  }

  .tableCell {
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    line-height: normal;
    font-weight: 600;
  }
}
