.loginForm {
  width: 100%;
}

.input {
  margin-bottom: 16px;
}

.button {
  margin-top: 24px;
}

.signUpContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 8px;
}
