@import '../../../styles/_variables.scss';

.horizontal {
  display: flex;
  align-items: center;
  gap: 16px;
}

.avatar {
  background-color: $secondary-color;
  height: 50px;
  width: 50px;
}
