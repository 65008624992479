@import '../../../styles/_variables.scss';

.container {
  display: block;
  max-width: 75rem;
  box-sizing: border-box;
  margin: 0 auto;
}

.title {
  padding: 1.5rem;

  h2 {
    font-size: 2rem;
  }
}

.contentContainer {
  display: grid;
  grid-template-columns: 50%;
  gap: 3rem;
  justify-content: center;

  @media (max-width: 900px) {
    grid-template-columns: 20rem;
    justify-content: center;
    gap: 1.5rem;
  }
}
