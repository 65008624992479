@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_breakpoints.scss';

.section {
  min-height: calc(100vh - $header-height);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 40px 32px;
}

.title {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px;
  gap: 32px;

  h1 {
    font-size: 2.25rem;
    font-weight: 700;
  }
}

.guidlineScreenshot {
  background: url('../../../../../assets//how-it-works.webp') no-repeat center center / contain;
  width: 100%;
  height: 300px;
}

.description {
  font-size: 1.125rem;
}

.guidelineContainer {
  display: flex;
  gap: 40px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
}

.guidelineItem {
  width: 100%;
  max-width: 600px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px;
  position: relative;
}

.guidelineItemName {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.guidelineItemNumber {
  background-color: #4851d4;
  color: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  height: 3rem;
  width: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -15px;
  left: -15px;
}

.guidelineItemTitle {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.1;
  width: 100%;
  padding-left: 32px;
}

.guidelineItemDescription {
  text-align: left;
  padding: 1rem 0rem;
  font-size: 0.8rem;
  color: $secondary-color;
}

@include bp-max('sm') {
  .section {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 24px 16px;
  }

  .title,
  .guidelineContainer {
    padding: 0px 16px;
    margin-bottom: 24px;
  }

  .guidelineContainer {
    align-items: center;
  }

  .guidelineItemTitle {
    font-size: 1rem;
  }

  .guidelineItemName {
    gap: 12px;
  }

  .guidlineScreenshot {
    width: 340px;
    height: 200px;
  }

  .guidelineItemDescription {
    margin-left: 32px;
    padding: 8px 0px;
  }
}
