@import '../../../styles/_variables.scss';
@import '../../../styles/_breakpoints.scss';


.container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90vh;
}

.containerItem {
  font-size: 1.2rem;
  background-color: white;
  border-radius: 14px;
  margin-top: 48px;
  padding: 48px 48px;
  width: 80%;
}

.icon {
  font-size: 7rem;
  color: $rejected-status;
}

.planContainer {
  margin: 0;
  border: 1px solid $rejected-status;
  border-radius: 14px;
}


@include bp-max('xs') {
  .containerItem {
    padding: 24px 12px;
  }
}
