@import '../../../styles/_variables.scss';
@import '../../../styles/breakpoints';


.wrapper {
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  margin: 0rem 0.75rem;
}

.dashboardContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

@include bp-max('lg') {
  .dashboardContainer {
    flex-direction: column;
  }
}