.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;

  & > form {
    max-width: 37rem;
  }
}

.title {
  margin: 1.5rem 0;
  font-weight: 600;
}

.description {
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  text-align: left;
}
