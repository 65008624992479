@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_breakpoints.scss';

.modal {
  gap: 0;
  width: 50%;
  min-width: 600px;
  padding: 1% 4%;

  form {
    gap: 0;
    height: 600px;

    & > ul {
      padding: 0;
      margin-top: 8px;
      overflow: auto;
      height: 700px;
    }

    & > div {
      width: calc(100% - 2px);
    }
  }
}

.list {
  width: 100%;
}

.disabledListItem {
  cursor: not-allowed;
  background-color: rgba(0, 0, 0, 0.04);
}

.listItem {
  display: flex;
  height: 42px;
  flex-direction: row;
  justify-content: space-between;
  color: #667a8a;
  border-radius: 4px;

  a {
    color: #667a8a;

    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }

  & > div {
    display: flex;
    align-items: center;
  }
  .avatar {
    background-color: #667a8a;
    height: 25px;
    width: 25px;
    font-size: 12px;
  }

  .info {
    margin: 0 8px;
    display: flex;
    flex-direction: column;

    .email {
      font-size: 12px;
    }
  }

  .speciality {
    font-size: 12px;
    background: #667a8a;
    padding: 2px 8px;
    border-radius: 6px;
    color: white;
    margin-left: 12px;
  }

  .role {
    color: #667a8a;
    text-transform: lowercase;
    font-size: 12px;
    width: 90px;
  }

  .delete {
    margin-left: 12px;
  }

  .roleSelect {
    min-width: 100px;
    background-color: transparent;

    div {
      color: rgba(0, 0, 0, 0.54) !important;
    }
  }
}

.search {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;

  padding-bottom: 8px;
  box-shadow: inset 0px -1.25px 0px -1px $primary-border-line-color;

  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      gap: 12px;
      display: flex;
    }

    & div {
      position: relative;
      top: 0;
      left: 0;
      transform: none;
      will-change: auto;
    }


    select {
      image-rendering: -webkit-optimize-contrast;
      image-rendering: crisp-edges;
      image-rendering: pixelated;
    }

    .listItemAddMember {
      height: 38px;
      width: 110px;
      padding: 0;
      text-transform: unset;
      font-size: 12px;
      line-height: normal;
    }
  }

  .listItemsCount {
    font-size: 12px;
    color: #667a8a;
  }
}

.roleItem {
  color: #667a8a;
  text-transform: lowercase;
  font-size: 12px;
  height: 22px;
}

.infiniteScrollWrapper {
  width: 100%;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: $primary-border-line-color;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }
}

.sceletonMobile {
  display: none;
  margin: 8px 0px;
}

@include bp-max('xs') {
  .modal {
    min-width: 95vw;
  }

  .list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
  }

  .listItem {
    padding: 8px;
    height: unset;
    flex-direction: column;
    border: 1px solid $primary-border-line-color;
    gap: 12px;

    & > div:first-child {
      flex-direction: column;
      align-items: center;
      gap: 4px;
      text-align: center;
    }

    .email {
      word-break: break-all;
    }

    .delete {
      margin-left: unset;
      margin-right: unset;
    }

    .avatar {
      height: 36px;
      width: 36px;
      font-size: 16px;
    }

    .avatarSkeleton {
      height: 36px !important;
      width: 36px !important;
    }
  }

  .sceletonMobile {
    display: block;
  }
}
