.gridItem {
  align-self: center;
}

.reviewLabel {
  text-align: left;
}

.reviewValue {
  text-align: left;
}

.onboardingReviewStatus {
  color: white;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 0.4rem;
  padding: 0.125rem;
}

.onboardingReviewFeedback {
  font-style: italic;
  align-self: center;
}
