@import '../../styles/_variables.scss';
@import '../../styles/_breakpoints.scss';

.footer {
  display: flex;
  position: relative;
  justify-content: space-around;
  width: 100%;
  background-color: $primary-footer-color;
  color: $secondary-text-color;
  box-shadow: 0px 0.25px 1px 0px rgba(58, 133, 255, 0.3);
  padding: 1.5rem 6rem 2rem 6rem;
  gap: 16px;
  overflow-x: hidden;
}

.logo {
  width: 196px;
}

.address {
  width: 12.25rem;
  font-size: 0.75rem;
  color: black;
  text-align: center;
}

.title {
  font-size: 1.2rem;
  font-weight: 600;
  color: black;
  padding: 0.5rem 0rem;
}

.items {
  display: flex;
  flex-direction: column;

  a {
    color: #3a86ff;
    font-size: 0.9rem;
    padding: 0.25rem 0rem;

    svg {
      height: 2rem;
      width: 2rem;
      margin-right: 0.25rem;
    }
  }
}

.socialMediaLinks {
  display: flex;
  gap: 16px;

  a {
    color: #3a86ff;
  }
}

.companySign {
  position: absolute;
  bottom: 0.5rem;
  width: 100%;
  text-align: center;

  color: black;
  font-size: 0.75rem;
}

.footerHrt {
  .items a,
  .socialMediaLinks a {
    color: #4851d4 !important;
  }
}

@include bp-max('sm') {
  .companyInfo {
    display: none;
  }

  .footer {
    padding: 10% 5%;
    justify-content: space-between;
  }

  .socialMediaLinks {
    display: flex;

    a {
      color: #3a86ff;

      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    }
  }
}

@include bp-max('xs') {
  .footer {
    padding: 24px 12px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .socialMediaLinks {
    justify-content: space-between;
    width: 100%;
    gap: 32px;
    margin-bottom: 24px;
  }
}
