@import '../../../../styles/variables.scss';
@import '../../../../styles/_breakpoints.scss';

.wrapper {
  min-height: 250px;
  max-height: 250px;

  width: 22%;
  min-width: 200px;
  background-color: #f8f9fd;
  padding: 14px;
  margin: 16px;

  .title {
    text-align: center;
  }

  .list {
    width: 100%;
    max-height: 200px;
    overflow: auto;
    padding: 0;

    &::-webkit-scrollbar {
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;
    }

    &:hover::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: $primary-border-line-color;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    & > div {
      overflow-x: hidden;
    }

    .listItem {
      display: flex;
      height: 48px;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      color: #667a8a;
      padding: 8px;
      border-bottom: 1px solid #afb8cf54;
      // overflow: scroll;

      & > div {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .avatar {
        background-color: #667a8a;
        height: 25px;
        width: 25px;
        font-size: 12px;
      }

      .info {
        margin-left: 8px;
        display: flex;
        flex-direction: column;
        width: 100%;

        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 2px;

        p {
          font-size: 14px;
          font-family: 'Inter';
          font-weight: 500;
        }

        a {
          color: #667a8a;
          &:hover {
            text-decoration: underline;
            text-decoration-thickness: 1px;
          }
        }
        .labelWrapper {
          height: 16px;
          font-size: 12px;
          line-height: 12px;
          gap: 6px;

          display: flex;

          &:first {
            margin-right: 14px;
          }

          & > div {
            max-width: 120px;
          }
        }

        .email {
          font-size: 12px;
          font-family: 'Inter';
          font-weight: 500;
        }
      }

      .label {
        font-size: 10px;
        text-transform: lowercase;
        // margin-left: 2px;
        height: 16px;
      }
    }
  }

  .button {
    width: 100%;
    text-align: left;
    padding-bottom: 8px;

    span {
      width: 100%;
    }
    p {
      width: 100%;
      font-size: 12px;
      font-family: 'Inter';
      font-weight: 500;
    }
    svg {
      width: 16px;
      height: 16px;
    }

    &:hover {
      color: $primary-color;
    }
  }
}

.renderOptionItem {
  display: flex;
  height: 42px;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  color: #667a8a;
  border-radius: 4px;
  align-items: center !important;
  padding: 0 8px !important;
  border: unset !important;
  cursor: unset !important;

  a {
    color: #667a8a;
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 1px;
    }
  }

  & > div {
    display: flex;
    align-items: center;
    width: 92%;
  }

  .avatar {
    background-color: #667a8a;
    height: 25px;
    width: 25px;
    font-size: 12px;
  }

  .info {
    margin: 0 8px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .infoRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .labelWrapper {
      height: 16px;
      font-size: 12px;
      line-height: 12px;

      &:first {
        margin-right: 14px;
      }
    }

    .email {
      font-size: 12px;
    }
  }

  .label {
    font-size: 10px;
    text-transform: lowercase;
    margin-left: 2px;
    height: 16px;
    width: 70px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}

.searchInput {
  margin-bottom: 16px;
  width: 100%;

  input {
    border-radius: 8px;
    padding: 8px;
    background-color: '#f8f9fd';
    font-size: 11px;
    font-family: 'Inter';

    &:focus {
      box-shadow: 0px 0px 0px 1px rgba(3, 102, 214, 0.3);
      border-color: #0366d6;
    }
  }
}

.labelName {
  white-space: nowrap;
  overflow: hidden;
  width: 160px;
  text-overflow: ellipsis;
  margin-right: 6px;
}

.labelAutocomplete {
  white-space: nowrap;
  overflow: hidden;
  width: 120px;
  text-overflow: ellipsis;
  margin-right: 6px;
  font-size: 14px;
}

.autoCompleteButton {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;

  & > svg {
    width: 16px;
    height: 16px;
  }
}

.search {
  height: 48px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .focusedSearch {
    box-shadow: 0px 0px 0px 1px rgba(3, 102, 214, 0.3) !important;
    border-color: #0366d6 !important;
  }
  .box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 28px;
    padding: 8px 16px;
    border-color: transparent;
    background-color: #f8f9fd;
    box-shadow: none;
    border: 0;
    border-radius: 8px;

    & > div {
      flex: 1;
      margin-left: 8px;
    }

    input {
      font-family: 'Inter';
      font-size: 11px;
    }
    svg {
      path {
        fill: $secondary-active-link-color;
      }
    }
  }
}

@include bp-max('lg') {
  .wrapper {
    width: unset;
    padding: 14px;
    margin: 0 0 16px 48px;
  }
}

@include bp-max('sm') {
  .wrapper {
    margin-left: 0;
  }
}
