@import '../../styles/_variables.scss';

.toggleButtonWrapper {
  height: 30px;
  border-radius: 26px;
  text-transform: none;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.toggleButtonSelected {
  color: $secondary-active-link-color !important;
}
