.helperText {
  height: 1rem;
  margin-left: 4px;
  margin-top: 0px;
  margin-right: 0px;
}

.textField {
  background-color: #f8f9fd;
}
