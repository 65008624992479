@import '../../../../styles/_variables.scss';

.section {
  .partners {
    height: 13rem;
    width: 100%;
    text-align: center;
    padding-top: 1rem;

    @keyframes slide {
      from {
        transform: translateX(0);
      }
      to {
        transform: translateX(-100%);
      }
    }

    .partnersTitle {
      font-size: 2rem;
      font-weight: 700;
      padding: 1rem 0rem;
    }

    .logos {
      overflow: hidden;
      padding: 1rem 0;
      white-space: nowrap;
      position: relative;

      &:before,
      &:after {
        position: absolute;
        top: 0;
        width: 16rem;
        height: 100%;
        content: '';
        z-index: 2;
      }

      &:before {
        left: 0;
        background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(250, 250, 250, 0.9294117647));
      }

      &:after {
        right: 0;
        background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(250, 250, 250, 0.9294117647));
      }
    }

    .logoSlide {
      display: inline-block;
      animation: 35s slide infinite linear;

      img {
        height: 3rem;
        margin: 0 2.5rem;
      }
    }

    .logos:hover {
      .logoSlide {
        animation-play-state: paused;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section {
    .partners {
      .logos {
        &:before,
        &:after {
          width: 9rem;
        }
      }
    }
  }
}
