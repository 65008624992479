@import '../../../../styles/_variables.scss';

.container {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  margin-top: 1rem;

  display: flex;

  .header {
    display: flex;
    padding-bottom: 0.75rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 2.5rem;
    border-bottom: 0.5px solid $primary-border-line-color;

    p {
      color: #000;
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .emptyList {
    color: black;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    display: flex;
    min-height: 12.5rem;
    align-items: center;
    justify-content: center;
  }

  .list {
    width: 100%;
    padding: 0;

    .thread {
      .itemButton {
        padding: 1rem;
        display: flex;
        justify-content: space-between;

        .itemInfo {
          display: flex;
          flex-direction: column;
          width: 100%;

          .date {
            p {
              color: $secondary-active-link-color;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
          }

          .title {
            margin: 0.5rem 0rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .textTitle {
              color: #1e1e1e;
              font-size: 1.75rem;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }

            .threadLabels {
              display: flex;
              gap: 0.75rem;
            }
          }

          .text {
            height: 3rem;
            max-height: 3rem;
            margin-top: 1rem;
            max-width: 80%;

            .ellipsis {
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;

              box-sizing: border-box;
              overflow-wrap: break-word;
              word-wrap: break-word;
              word-break: break-all;

              * {
                color: $secondary-color;
                font-style: normal;
                line-height: normal;
                font-size: 0.875rem !important;
                padding: 0;
                margin: 0;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .container {
    .list {
      .thread {
        .itemButton {
          padding: 1rem 0.25rem;

          .itemInfo {
            .title {
              .textTitle {
                font-size: 1.25rem;
              }
            }
            .text {
              margin-top: 0.5rem;
            }
          }
        }
      }
    }
  }
}
