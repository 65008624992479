@import '../../../../../styles/variables.scss';
@import '../../../../../styles/breakpoints.scss';

.loaderContainer {
  position: relative;
}

.info {
  margin-bottom: 20px;
}

.description {
  color: $secondary-text-color;
  font-size: 0.875rem;
}

.tableCell {
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
}

.button {
  text-align: right;

  text-transform: unset;
  font-size: 12px;
  line-height: normal;
}

@include bp-max('sm') {
  .tableContainer {
    width: 100%;
    overflow-x: hidden
  }

  .tableCell, .tableHeadCell {
    font-size: 10px;
    padding: 6px;
  }
}

