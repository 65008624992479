@import '../../../styles/_variables.scss';

.loaderContainer {
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;

  span {
    color: $primary-color;
    height: 3px;
  }
}
