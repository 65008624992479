@import '../../styles/_variables.scss';
@import '../../styles/breakpoints.scss';

.header {
  z-index: 10;
  display: flex;
  position: sticky;
  top: env(safe-area-inset-top);
  justify-content: space-around;
  background-color: $primary-header-color;
  flex-wrap: wrap;
  min-height: $header-height;
  box-shadow: 0px 0.25px 1px 0px rgba(58, 133, 255, 0.2);
}

.companyLogo,
.landingButtons,
.joinButtons {
  width: 100%;
}

.logo {
  width: 156px;
}

@include bp-min('md') {
  .companyLogo,
  .landingButtons,
  .joinButtons {
    width: auto;
  }
}

.menuButton {
  color: $secondary-color;
  font-weight: 500;

  text-transform: none;
  border-radius: 0.5rem;
  font-size: 1rem;
}

.companyLogo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.companyLogo svg {
  font-size: 10rem;
  max-height: 4rem;
  cursor: pointer;
}

.landingButtons {
  display: flex;
  gap: 1.5em;
  justify-content: center;
  padding: 1rem 0px;
}

.joinButtons {
  display: flex;
  gap: 2rem;
  justify-content: center;
  padding: 1rem 0px;
}

.signInButton {
  text-decoration: unset;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
}

.bookDemoButton {
  padding: 0.375rem 0.75rem;
  width: 9rem;
}

.activeLinkClass {
  font-weight: 600;
  color: $primary-color;

  & > a {
    font-weight: 600;
    color: $primary-color;
  }
}

@include bp-max('md') {
  .header {
    height: 60px;
    & > .landingButtons,
    & > .joinButtons {
      display: none;
    }

    .drawerWrapper {
      position: absolute;
      left: 5%;

      svg {
        fill: #818285;
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .menuButton {
    font-weight: 600;
    padding: 0px;
    padding: 1rem 0rem;
    width: 100%;
    border: none;
    height: unset;
    line-height: 1rem;
  }

  .drawerPaper {
    width: 70%;
    padding: 1rem;
    justify-content: space-between;

    .landingButtons,
    .joinButtons {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 0.5rem;
      padding: 0px;
    }

    .activeLinkClass {
      background: #e9f5fe;
      border-radius: 4px;

      .text {
        span {
          color: $secondary-active-link-color;
          font-weight: 600;
        }
      }

      .icon {
        color: $secondary-active-link-color;

        div {
          background: $secondary-active-link-color;
        }

        svg {
          path {
            fill: $secondary-active-link-color;
          }
        }
      }
    }
  }
}
