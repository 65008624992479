@import '../../../../../styles/variables.scss';

.menu {
  ul {
    padding: 16px;

    gap: 8px;
    display: flex;
    flex-direction: column;

    a {
      text-align: center;
    }
  }
}

.MuiDrawerPaper {
  width: 250px;
}
