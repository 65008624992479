@import '../../../styles/_variables.scss';
@import '../../../styles/breakpoints.scss';

.container {
  min-height: 100vh;
  margin: 0rem 0.75rem;

  background-color: white;
  border-radius: 0.5rem;
}

.tabList {
  border-bottom: 1px solid $primary-border-line-color;

  .tabItem {
    text-transform: unset;
  }
}

.tabPanel {
  padding: 1rem;
}

@include bp-max('sm') {
  .container {
    min-height: calc(100vh - 4rem);
    margin: 0;
  }
}
