@import '../../styles/_variables.scss';
@import '../../styles/_breakpoints.scss';

.dialogContainer {
  border-radius: 14px;
}

.title {
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 8px 0px;
}

.content {
  margin: 8px 0px;
  li {
    margin: 8px 0px;
  }

  p {
    font-size: 14px;
    color: #495057;
  }
}

.actionButtons {
  padding: 16px 48px 28px 48px;
  gap: 12px;

  .cancel {
    background-color: transparent;
  }
}

@include bp-max('sm') {
  .dialog {
    margin: 0px;
    width: calc(100vw - 16px);
    max-height: calc(100vh - 32px);

    & :global(.MuiDialogContent-root) {
      padding: 12px;
    }
  }

  .actionButtons {
    padding: 16px;
  }
}

@include bp-max('xs') {
  .actionButtons {
    flex-direction: column-reverse;
  }
}
