@import '../../../../styles/_variables.scss';
@import '../../../../styles/_breakpoints.scss';

.section {
  min-height: calc(100vh - $header-height);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
}

.title {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 1rem;

  h1 {
    font-size: 2.25rem;
    font-weight: 700;
    padding: 1rem 0rem;
  }

  & > div {
    width: 100%;
  }

  @media (min-width: 768px) {
    & > div {
      width: 60%;
    }
  }
}

.description {
  font-size: 1.3rem;
  font-weight: 500;
}

.sectionItem {
  width: 60%;
}

.sectionItemTitle {
  font-weight: 600;
  height: 80px;
}

.sectionItemDescription {

}

@include bp-max('sm') {
  .section {
    min-height: unset;
    justify-content: flex-start;
  }

  .sectionItem {
    width: 100%;
  }
}
