@import '../../../../styles/breakpoints.scss';

.container {
  padding: 16px;
}

.loaderContainer {
  position: relative;
  margin-top: 16px;
}

.tableCell {
  font-size: 12px;
  font-style: normal;
  line-height: normal;
  font-weight: 600;
}

.button {
  height: 38px;
  width: 110px;
  padding: 0;
  text-align: right;

  text-transform: unset;
  font-size: 12px;
  line-height: normal;
}


@include bp-max('sm') {
  .container {
    padding: 16px 8px;
  }
}
