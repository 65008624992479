@import '../../styles/breakpoints';
@import '../../styles/variables';


.planContainer {
  width: 100%;
  margin: 48px 0px;
  display: flex;
  justify-content: center;
}

.planCard {
  padding: 32px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 14px;
}
.name {
  font-weight: 700;
  font-size: 1.5rem;
  margin: 12px 0px;
}

.description {
  color: $secondary-text-color;
  font-size: 0.8rem;
}

.amountWrapper {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 0px;
}

.amount {
  font-size: 1.8rem;
  font-weight: 700;
}

.interval {
  color: $secondary-text-color;
  font-size: 0.8rem;
}
