@import '../../styles/_variables.scss';
@import '../../styles/_breakpoints.scss';

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 16px;
  border-radius: 14px;
  width: 80%;
  max-height: calc(100vh - 16px);
  overflow-y: auto;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  &::-webkit-scrollbar {
    width: 6px;
    display: block;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: $primary-border-line-color;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-track-piece:start {
    background: transparent;
    margin-top: 12px;
  }

  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: 12px;
  }
}

.closeButton {
  position: absolute;
  right: 4px;
  top: 4px;
}

@include bp-max('md') {
  .modal {
    width: 90%;
  }
}

@include bp-max('sm') {
  .modal {
    width: calc(100vw - 16px);
    max-height: calc(80vh - 58px);
    padding: 12px;
  }
}
