body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */

  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

:root {
  font-size: 16px;
}

@media (max-width: 768px) {
  :root {
    font-size: 15px;
  }
}

@media (max-width: 480px) {
  :root {
    font-size: 14px;
  }
}

/* Vanilla cookie consent */
#cc-main {
  color-scheme: light;

  --cc-font-family: 'Inter', sans-serif;

  --cc-bg: #f9faff;
  --cc-secondary-color: #112954;


  --cc-btn-primary-bg: #0c7fda;
  --cc-btn-primary-color: var(--cc-bg);
  --cc-btn-primary-hover-bg: #0f69b3;
  --cc-btn-primary-hover-color: #fff;

  --cc-btn-secondary-bg: #667a8a23;
  --cc-btn-secondary-color: var(--cc-secondary-color);
  --cc-btn-secondary-hover-bg: #b8bbc372;
  --cc-btn-secondary-hover-color: #1c1d20;

  --cc-toggle-readonly-bg: #cbd8f1;
  --cc-toggle-on-knob-bg: var(--cc-bg);
  --cc-toggle-off-bg: #8fa8d6;
  --cc-toggle-on-bg: var(--cc-btn-primary-bg);
  --cc-toggle-readonly-knob-bg: var(--cc-bg);

  --cc-cookie-category-block-bg: #e9f5fe;
  --cc-cookie-category-block-border: #ebeff9;
  --cc-cookie-category-block-hover-bg: #dbe5f9;
  --cc-cookie-category-block-hover-border: #dbe5f9;
  --cc-cookie-category-expanded-block-hover-bg: #e9f5fe;
  --cc-cookie-category-expanded-block-bg: #e9f5fe;
}
