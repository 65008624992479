@import '../../../../../styles/_variables.scss';
@import '../../../../../styles/_breakpoints.scss';

.threadEditorWrapper {
  margin-left: 48px;
  width: calc(75% - 48px);
}
.threadEditorButton {
  margin: 16px 0;
  display: flex;
  justify-content: right;

  .button {
    width: 280px;

    text-transform: unset;
    font-size: 14px;
    line-height: normal;
  }
}

.threadMessageUploadContainer {
  margin: 12px 0px;
}

@include bp-max('md') {
  .threadEditorWrapper {
    margin-left: 0px;
    width: 100%;
  }
}
