.error {
  box-shadow: unset !important;
  border: 1px solid red !important;
}

.helperText {
  height: 16px;
  margin-left: 4px;
  margin-top: 0px;
  margin-right: 0px;
}

.editorWrapper {
  box-shadow: 0 1px 1.5px #5d7285;
  border-radius: 8px;
  background-color: #f8f9fd;
  border: 1px solid transparent;
  max-height: 400px;
  overflow-y: auto;
}

.editor {
  padding: 8px 16px;
  color: #495057;
  font-size: 14px;
  font-family: Poppins, sans-serif;
  margin: 0;
  min-height: 220px;

  width: 100%;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.toolbar {
  padding: 16px !important;
  border-top-right-radius: 8px !important;
  border-top-left-radius: 8px !important;
  border: unset !important;
  margin: 0 !important;
}

.fontSize {
  border-radius: 8px !important;
  height: 24px !important;
  width: 68px !important;
  span {
    text-align: center;
  }
}

.fontSizeDropdown {
  overflow-y: hidden !important;

  &:hover {
    box-shadow: none !important;
  }
}

.blockType {
  border-radius: 8px !important;
  height: 24px !important;
  width: 100px !important;
  span {
    text-align: center;
  }
}

.blockTypeDropdown {
  overflow-y: hidden !important;

  &:hover {
    box-shadow: none !important;
  }
}

:global {
  .rdw-option-wrapper {
    border-radius: 8px !important;
    height: 12px !important;
    width: 42px;
    margin: 4px !important;
  }

  .rdw-option-wrapper:hover {
  }

  .rdw-option-active {
    background-color: #bababa0a;
  }

  .rdw-inline-wrapper {
    margin-bottom: 0px;
  }

  .rdw-editor-toolbar {
    & > div {
      margin-bottom: 0px;
    }
  }

  .rdw-emoji-wrapper {
    .rdw-emoji-modal {
      left: auto;
      right: 5px;
    }
  }

  .DraftEditor-editorContainer {
    height: 210px !important;
    overflow: auto;
  }

  .public-DraftStyleDefault-block {
    margin: 0 !important;
    margin-bottom: 6px !important;
  }
}
