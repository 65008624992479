@import '../../../styles/_variables.scss';

.listSearch {
  height: 72px;
  box-shadow: inset 0 -0.25px 0 0 $primary-border-line-color;

  display: flex;
  align-items: center;
  justify-content: center;

  .search {
    height: 32px;
    padding: 16px;
    width: 100%;

    & > div {
      height: 32px;
    }
  }

  & > div {
    height: 32px;
  }
}
