@import '../../../../../styles/variables.scss';

.loaderContainer {
  position: relative;
}

.info {
  margin-bottom: 20px;
}

.description {
  color: $secondary-text-color;
  font-size: 0.875rem;
}
