@import '../../../../../styles/variables.scss';

.listItem {
  display: block;
  padding: 0;
}
.button {
  padding: 0px;
  height: 34px;
  text-align: center;
  border-radius: 4px;
}

.text {
  span {
    color: $secondary-text-color;
    font-family: 'Poppins', sans-serif;
    font-size: 14.636px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.954px;
    letter-spacing: 0.146px;
  }
}

.leaveButton {
  background-color: red;

  &:hover {
    background-color: rgb(171, 7, 7);
  }

  .leaveText {
    span {
      color: white;
    }
  }
}
