.wrapper {
  max-width: 50rem;

  .item {
    padding: 0.25rem 0rem;
  }

  .buttonContainer {
    text-align: right;
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    .buttonContainer {
      text-align: center;

      & > button {
        width: 100%;
      }
    }
  }
}
