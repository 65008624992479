@import '../../../../styles/_variables.scss';


.containerItemLabel {
  color: $secondary-color;
  font-size: 0.875rem;
  font-weight: 500;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.container {
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.containerItemValue {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: flex-start;
}


@media (max-width: 768px) {
  .containerItemValue {
    .chipValue {
      height: 18px;
    }  
  }
}
