@import '../../../../styles/_variables.scss';

.section {
  min-height: calc(100vh - $header-height);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;

  .title {
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem 1rem;

    h1 {
      font-size: 2.25rem;
      font-weight: 700;
      padding: 1rem 0rem;
    }

    & > div {
      width: 100%;
    }

    @media (min-width: 768px) {
      padding: 3rem;
      & > div {
        width: 60%;
      }
    }
  }

  .guidelineContainer {
    display: flex;
    gap: 1rem;
    padding: 0% 5%;

    .guidlineLeft,
    .guidlineRight {
      width: 30%;
      margin-top: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
    }

    .guidlineScreenshotContainer {
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;

      .guidlineScreenshot {
        border-radius: 0.5rem;
        background: url('../../../../assets//how-it-works.webp') no-repeat center center / contain;
        width: 100%;
        height: 100%;
      }
    }

    .guidelineItem {
      width: 100%;
      margin: 1.5rem 0.5rem;
      height: 30%;
      .guidelineItemName {
        display: flex;
        align-items: center;
        gap: 0.75rem;

        .guidelineItemNumber {
          background-color: rgba(58, 133, 255, 0.862745098);
          color: white;
          border-radius: 0.5rem;
          font-size: 1.5rem;
          font-weight: 700;
          height: 3rem;
          width: 3rem;

          display: flex;
          align-items: center;
          justify-content: center;
        }

        .guidelineItemTitle {
          font-size: 1.5rem;
          font-weight: 700;
          text-align: left;
        }
      }

      .guidelineItemDescription {
        text-align: left;
        padding: 1rem 0rem;
        font-size: 0.8rem;
        color: $secondary-color;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section {
    min-height: unset;

    justify-content: flex-start;
    .guidelineContainer {
      padding: 0rem 1rem;
      position: relative;
      height: 90%;
      display: flex;
      flex-direction: column;

      .guidlineLeft,
      .guidlineRight {
        width: 100%;
        order: 2;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .guidelineItem {
          .guidelineItemName {
            .guidelineItemTitle {
              font-size: 1rem;
            }
          }

          .guidelineItemDescription {
          }
        }
      }
      .guidlineScreenshotContainer {
        order: 1;
        width: 100%;

        .guidlineScreenshot {
          width: 340px;
          height: 200px;
        }
      }
    }
  }
}
