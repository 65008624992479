@import '../../../../styles/breakpoints.scss';

.container {
  padding: 16px;
}

.loaderContainer {
  position: relative;
  margin-top: 16px;
}

@include bp-max('sm') {
  .container {
    padding: 16px 8px;
  }
}
