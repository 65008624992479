@import '../../styles/_variables.scss';
@import '../../styles/breakpoints.scss';

.itemWrapper {
  margin-top: 0.75rem;
}

.itemTitle {
  border-bottom: 0.5px solid $primary-border-line-color;
  padding: 0.25rem 0rem;
  height: 45px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
}

.itemActions {
  height: 40px;
  display: flex;
  align-items: center;
}

.itemContent {
  padding: 0.75rem;
  font-size: 1rem;
}

@include bp-max('sm') {
  .itemTitle {
    font-size: 0.875rem;
  }
}
