@import '../../../../styles/variables.scss';
@import '../../../../styles/breakpoints.scss';

.threadsWrapper {
  width: 100%;

  .search {
    max-height: 30px;
    max-width: 300px;

    & > div {
      height: 27px;
      border-radius: 26px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      background-color: #f8f9fd;

      input {
        padding: 4px 0 4px;

        color: #000;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        &::placeholder {
          color: rgba(0, 0, 0, 0.54);
          opacity: 1;
        }
      }

      svg {
        height: 16px;
        width: 16px;
      }
    }
  }

  .toggleButton {
    min-width: 92px;
  }
}

.error {
  padding: 12px;
}

@include bp-max('sm') {
  .threadsWrapper {
    padding-bottom: $bottom-nav-height;
  }
}
