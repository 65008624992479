@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_breakpoints.scss';

.container {
  width: 100%;

  .fileUpload {
    display: flex;
    gap: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100%;
    border-radius: 10px;
    border: 2px dashed $primary-border-line-color;
    cursor: pointer;
    transition:
      background 0.2s ease-in-out,
      border 0.2s ease-in-out;
    position: relative;

    &:hover {
      border-color: #111;
      background: #eee;
    }

    .uploadInput {
      width: 100%;
      height: 100%;
      overflow: hidden;
      white-space: nowrap;
      clip: rect(0 0 0 0);
      position: absolute;
    }
  }

  .fileAttachments {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 4px 8px;
    gap: 12px;
  }

  .fileAttachment {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .fileUploadActive {
    border-color: $secondary-active-link-color;
    color: $secondary-active-link-color;
  }
}

.error {
  color: red;
  .fileUpload {
    color: red;
    border-color: red;
  }

  .fileAttachments {
    color: red;
    svg {
      color: red;
    }
  }
}
