@import '../../styles/_variables.scss';

.modalButtonContainer {
  width: 100%;

  .modalButton {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 40%;
    padding: 0;

    border-radius: 8px;
    text-transform: unset;
    height: 56px;
  }
}
