$breakpoints: (
  xxs: 375px,
  xs: 600px,
  sm: 768px,
  md: 900px,
  lg: 1200px,
  xl: 1536px,
);

@mixin mq($min-width: null, $max-width: null) {
  @if $min-width and $max-width {
    @media (min-width: $min-width) and (max-width: $max-width) {
      @content;
    }
  } @else if $min-width {
    @media (min-width: $min-width) {
      @content;
    }
  } @else if $max-width {
    @media (max-width: $max-width) {
      @content;
    }
  }
}

@mixin max-screen($max) {
  @include mq($max-width: $max) {
    @content;
  }
}

@mixin min-screen($min) {
  @include mq($min-width: $min) {
    @content;
  }
}

@mixin bp-min($breakpoint) {
  @include min-screen(map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin bp-max($breakpoint) {
  @include max-screen(map-get($breakpoints, $breakpoint) - 1) {
    @content;
  }
}
