@import '../../../../styles/_variables.scss';

.section {
  min-height: calc(100vh - $header-height);

  display: flex;
  justify-content: space-around;
  flex-direction: row;
  padding: 0% 5%;

  .title {
    font-size: 2.25rem;
    font-weight: 700;
  }

  .text {
    margin: 1.5rem 0rem;

    a {
      text-decoration: underline;
    }
  }

  .infoWrapper {
    text-align: left;
    width: 40%;
    margin-top: 15%;

    p {
      font-size: 14px;
    }

    span {
      font-weight: 600;
    }

    a {
      color: black;
      font-style: italic;
    }
  }

  .formWrapper {
    margin-top: 15%;
    width: 40%;
    position: relative;

    .contactUsImage {
      position: absolute;
      height: 40%;
      min-height: 25rem;
      width: 90%;
      z-index: 1;

      background: url('../../../../assets/contact-us.webp') no-repeat center center / cover;
      border-radius: 0.5rem;

      top: -10%;
      right: -10%;
    }

    .contactUsForm {
      background-color: white;
      position: relative;
      padding: 2.25rem;
      border-radius: 0.5rem;
      width: 75%;
      z-index: 2;
      box-shadow: 0px 0px 2px 0px $primary-border-line-color;

      .contactUsFormTitle {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 700;
        margin-bottom: 1.5rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .section {
    display: flex;
    flex-direction: column;
    padding: 5%;

    .infoWrapper {
      width: 100%;
      margin-top: 1rem;

      .title {
        text-align: center;
      }
    }

    .formWrapper {
      width: 90%;

      .contactUsForm {
        width: 90%;
      }
    }
  }
}
