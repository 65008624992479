@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_breakpoints.scss';

.sceletonMobile {
  display: none;
  margin: 8px 0px;
}

.content {
  display: flex;
  gap: 8px;
  align-items: center;
  width: 100%;
}

.checkboxContainer {
  width: 28px;
  margin-left: auto;
}

@include bp-max('xs') {
  .name {
    width: 120px !important;
  }

  .avatarSkeleton {
    height: 36px !important;
    width: 36px !important;
  }

  .sceletonMobile {
    display: block;
  }

  .checkboxContainer {
    margin-left: unset;
    width: unset;
  }
}
