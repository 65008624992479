@import '../../../styles/_variables.scss';

.container {
  .chip {
    font-weight: normal;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .chip {
      height: 2rem;
    }
  }
}
