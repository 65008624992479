@import '../../../../styles/_variables.scss';

.menu {
  ul {
    padding: 1rem 1.25rem 1rem 1.25rem;

    a {
      text-align: center;
      margin: 0rem;
      height: 2rem;
    }

    li {
      margin-top: 0.5rem;
    }

    li:first-child {
      margin-top: 0rem;
    }
  }
}

.MuiDrawerPaper {
  width: 15.625rem;
}
