@import '../../../../styles/_variables.scss';
@import '../../../../styles/_breakpoints.scss';

.section {
  min-height: calc(100vh - $header-height);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 1rem;
}

.title {
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem 1rem;

  h1 {
    font-size: 2.25rem;
    font-weight: 700;
    padding: 1rem 0rem;
  }

  & > div {
    width: 100%;
  }

  @media (min-width: 768px) {
    padding: 3rem;
    & > div {
      width: 60%;
    }
  }
}

.guidelineContainer {
  display: flex;
  gap: 1rem;
  padding: 0% 5%;
  width: 100%;
  flex-grow: 0.75;
}

.guidlineLeft,
.guidlineRight {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.guidlineScreenshotContainer {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.guidlineScreenshot {
  border-radius: 0.5rem;
  background: url('../../../../assets//how-it-works.webp') no-repeat center center / contain;
  width: 100%;
  height: 100%;
}

.guidelineItem {
  width: 100%;
  margin: 1.5rem 0.5rem;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 12px;
  position: relative;
}

.guidelineItemName {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.guidelineItemNumber {
  background-color: rgba(58, 133, 255, 0.7);
  color: white;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 700;
  height: 3rem;
  width: 3rem;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -15px;
  left: -15px;
}

.guidelineItemTitle {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.1;
  width: 100%;
  padding-left: 32px;
}

.guidelineItemDescription {
  text-align: left;
  padding: 1rem 0rem;
  font-size: 0.8rem;
  color: $secondary-color;
}

@include bp-max('sm') {
  .section {
    min-height: unset;
    justify-content: flex-start;
  }

  .guidelineContainer {
    padding: 0rem 1rem;
    position: relative;
    height: 90%;
    display: flex;
    flex-direction: column;
    gap: 0;
  }

  .guidlineLeft,
  .guidlineRight {
    width: 100%;
    order: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0;
  }

  .guidelineItem {
    margin: 24px 0px;
  }

  .guidelineItemTitle {
    font-size: 1rem;
  }

  .guidelineItemName {
    gap: 12px;
  }

  .guidlineScreenshotContainer {
    order: 1;
    width: 100%;
  }

  .guidlineScreenshot {
    width: 340px;
    height: 200px;
  }

  .guidelineItemDescription {
    margin-left: 32px;
    padding: 8px 0px;
  }
}

@include bp-max('xs') {
  .guidelineItemNumber {
    top: -10px;
    left: -6px;
  }

  .guidelineItem {
    margin: 20px 0px;
  }
}
