@import '../../../../styles/_variables.scss';
@import '../../../../styles//breakpoints';

$transition-time: 2.3s;

.section {
  padding: 0% 5%;
  min-height: calc(100vh - $header-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $primary-color;
}

.title {
  padding: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  color: $white;

  div {
    width: 100%;
  }

  & > h1 {
    font-size: 2.25rem;
    font-weight: 700;
    padding: 1rem 0rem;
  }

  @media (min-width: 768px) {
    div {
      width: 80%;
    }
  }
}

.cards {
  display: flex;
  justify-content: center;
  gap: 25px;
  width: 100%;
}
.cardItem {
  position: relative;
  height: 400px;
  width: 400px;
  margin: 10px 0;
  transition: ease all $transition-time;
  perspective: 1200px;

  &:hover {
    .cover {
      transform: rotateX(0deg) rotateY(-180deg);
      &:before {
        transform: translateZ(30px);
      }
      &:after {
        background-color: #667a8a;
      }
      .cardItemTitle {
        transform: translateZ(100px);
      }
      .cardItemIcon {
        transform: translateZ(60px);
      }
      .cardItemDescription {
        transform: translateZ(-60px) rotatey(-180deg);
      }
    }
  }

  &.public {
    .cover {
      background-image: url('../../../../../public/assets/public-group.webp');
    }
  }
  &.private {
    .cover {
      background-image: url('../../../../../public/assets/private-group.webp');
    }
  }
  &.mdt {
    .cover {
      background-image: url('../../../../../public/assets/mdt-group.webp');
    }
  }
}

.cover {
  position: absolute;
  height: 100%;
  width: 100%;
  transform-style: preserve-3d;
  transition: ease all $transition-time;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    border: 5px solid rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.3);
    top: 20px;
    left: 20px;
    right: 20px;
    bottom: 20px;
    z-index: 2;
    transition: ease all $transition-time;
    transform-style: preserve-3d;
    transform: translateZ(0px);
    background-color: #667a8acc;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    transition: ease all 1.3s;
  }
}

.cardItemTitle {
  font-weight: 600;
  position: absolute;
  bottom: 55px;
  left: 50px;
  color: $white;
  transform-style: preserve-3d;
  transition: ease all $transition-time;
  z-index: 3;
  transform: translateZ(0px);
}

.cardItemIcon {
  font-weight: 200;
  position: absolute;
  top: 55px;
  right: 50px;
  color: white;
  transform-style: preserve-3d;
  transition: ease all $transition-time;
  z-index: 4;
  font-size: 2rem;
  transform: translateZ(0px);
}

.cardBack {
  position: absolute;
  height: 100%;
  width: 100%;
  background: $white;
  transform-style: preserve-3d;
  transition: ease all $transition-time;
  transform: translateZ(-1px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.cardItemDescription {
  transform-style: preserve-3d;
  transition:
    ease transform $transition-time,
    ease background 0.5s;
  transform: translateZ(-1px) rotatey(-180deg);
  background: transparent;
  border: 1px solid #667a8a;
  font-weight: 200;
  color: #667a8a;
  padding: 14px 32px;
  outline: none;
  text-decoration: none;
  max-width: 80%;
}

.cardItemIcon {
  border-radius: 11px;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 3rem;
  }
}

.cardItemTitle {
  font-size: 2rem;
  font-weight: 600;
  margin: 0.75rem 0;
  max-width: 75%;
}

.cardItemDescription {
  font-size: 1rem;
  color: $secondary-color;
}

.cardItemActive {
  background-color: #e9f5fe;
  transition: background-color 0.2s ease 0.3s;

  .cardItemIcon {
    background-color: #3a85ffdc;
    color: white;
  }
}

@include bp-max('lg') {
  .cardItemTitle {
    font-size: 1.65rem;
    margin: 0.45rem 0;
    left: 45px;
  }

  .cardItemDescription {
    padding: 8px 16px;
  }

  .cardItemIcon {
    top: 30px;
    right: 30px;
  }
}

@include bp-max('md') {
  .cards {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    margin-bottom: 64px;
  }

  .cardItem {
    width: 500px;
    height: 200px;
  }
}

@include bp-max('sm') {
  .section {
    min-height: calc(70vh - 5rem);
  }

  .cards {
    gap: 8px;
  }

  .title {
    padding: 2rem 1rem;
  }

  .cardItem {
    width: 280px;
    height: 300px;
  }
}
