body {
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
}

.native-top-inset {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: env(safe-area-inset-top);
  background-color: white;
  z-index: 1299;
}

.native-bottom-inset {
  position: fixed;
  bottom: 0px;
  width: 100vw;
  height: env(safe-area-inset-bottom);
  background-color: white;
  z-index: 1299;
}
