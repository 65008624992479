.title {
  text-align: center;
}

.buttonContainer {
  width: 100%;
  margin: 3rem 0rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  button {
    height: 3rem;
    padding: 0.775rem 3rem;
    // width: 4rem;
  }
}

.buttonHidden {
  visibility: hidden;
}
