@import '../../../styles/variables.scss';
@import '../../../styles/breakpoints.scss';

.section {
  width: 100%;
  min-height: calc(100vh - $header-height);
  display: grid;
  grid-template-columns: 600px 1fr;
  align-items: center;
  padding: 64px 0 64px 64px;
  overflow-x: hidden;
  gap: 80px;
}

.info {
  padding: 32px;
  border-radius: 12px;
  width: max(50%, 600px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.infoTitle {
  font-size: 3rem;
  font-weight: 800;
  opacity: 0;

  animation: smooth-appear 0.75s ease forwards;

  em {
    border: 0.2rem solid $primary-color;
    padding: 0 0.5rem;
    white-space: nowrap;
    font-style: normal;
  }
}

.descriptionContainer {
  margin: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  position: relative;
  width: 100%;
  height: 260px;
}

.description {
  font-size: 1.125rem;
  color: $black;
  position: absolute;
  width: 100%;
  left: -100%;
  transform: translateX(-50%);
  opacity: 0;

  animation: smooth-appear-left 0.5s ease forwards;
}

.joinButton {
  width: 35%;
  text-transform: none;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.5rem;
  height: 3.5rem;
  box-shadow: none;
  opacity: 0;
  animation: smooth-appear 2s ease forwards;

  &:hover {
    box-shadow: 1px 4px 20px 0px rgba(0, 0, 0, 0.2);
  }

  &Mobile {
    display: none;
  }
}

.imageContainer {
  border-radius: 0px;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
  opacity: 0;
  animation: smooth-appear 1s ease forwards;

  .img {
    height: calc(100vh - 200px);
  }

  .imgMobile {
    display: none;
  }
}

@keyframes smooth-appear-left {
  to {
    left: 50%;
    opacity: 0.75;
  }
}

@keyframes smooth-appear {
  to {
    opacity: 1;
  }
}

@include bp-max('md') {
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    padding: 32px;
  }

  .infoTitle {
    text-align: center;
  }

  .info {
    align-items: center;
  }

  .descriptionContainer {
    margin: 20px 0;
    height: 210px;
  }

  .description {
    text-align: center;
  }

  .imageContainer {
    max-width: 100%;
    border-radius: 12px;

    .img {
      height: 300px;
    }
  }

  .infoTitle {
    font-size: 2.5rem;
  }

  .description {
    font-size: 1rem;
  }

  .info {
    width: 80%;
  }
}

@include bp-max('sm') {
  .section {
    gap: 24;
    min-height: 100vh;
    padding: 32px 16px;
  }

  .infoTitle {
    font-size: 2rem;
    text-align: center;
  }

  .info {
    width: 100%;
    max-width: 400px;
    padding: 0px;
  }

  .descriptionContainer {
    height: unset;

    .description {
      position: relative;
    }
  }

  .description {
    min-width: 100%;
  }

  .joinButton {
    width: 100%;
    display: none;

    &Mobile {
      display: flex;
    }
  }

  .imageContainer {
    max-width: 100%;

    .img {
      display: none;
    }

    .imgMobile {
      display: block;
      max-width: 100%;
      width: 375px;
    }
  }
}
