@import '../../../styles/_variables.scss';

.search {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  .box {
    display: flex;
    align-items: center;
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    border-color: transparent;
    background-color: #f8f9fd;
    box-shadow: none;
    border: 0;
    border-radius: 1rem;

    & > div {
      flex: 1;
      margin-left: 1rem;
    }

    input {
      font-size: 1rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;

      &::placeholder {
        color: rgba(0, 0, 0, 0.54);
        opacity: 1;
      }
    }

    svg {
      path {
        fill: $secondary-active-link-color;
      }
    }
  }
}

.focusedSearch {
  box-shadow: 0px 0px 0px 1px rgba(3, 102, 214, 0.3) !important;
  border-color: #0366d6 !important;
}
