@import '../../../../../../styles/_variables.scss';
@import '../../../../../../styles/_breakpoints.scss';

.avatar {
  background-color: #667a8a;
  height: 25px;
  width: 25px;
  font-size: 12px;
}

.info {
  margin: 0 8px;
  display: flex;
  flex-direction: column;

  .email {
    font-size: 12px;
  }
}

.speciality {
  font-size: 12px;
  background: #667a8a;
  padding: 2px 8px;
  border-radius: 6px;
  color: white;
  margin-left: 12px;
}

.controlContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
}

@include bp-max('xs') {
  .email {
    word-break: break-all;
  }

  .info {
    align-items: center;
  }

  .addMember {
    margin-left: unset;
    margin-right: unset;
  }

  .avatar {
    height: 36px;
    width: 36px;
    font-size: 16px;
  }
}
